import { defineStore, acceptHMRUpdate } from 'pinia'
import { computed, reactive, markRaw } from 'vue'
import { filter, includes } from 'lodash'
import { collection, getFirestore } from "firebase/firestore"
import fields from '@/../functions/common/fields/contentPage'
import form from '@/admin/ContentPages/ContentPageForm' 

export const useContentPageStore = (contentPageId, path = null) => defineStore(`contentPages/${contentPageId}`, {
    state: () => ({
      document: reactive({}),
      form: markRaw(form),
      fullPath: `${path}/${contentPageId}`
    }),
    getters: {
      fields: state => fields,
      contentPage: state => state.document
    },
    document: true
  }
)()