<template>
  <div
    :style="{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignContent: 'center',
      alignItems: 'stretch'
    }"
    class="text-center"
  >
    <el-button
      v-for="method in availableMethods"
      :key="method.id"
      :style="{
        width: '100%',
        margin: '0 auto',
        marginBottom: '1em'
      }"
      type="primary"
      @click="() => selectMethod(method.id)"
    >
      <el-image 
        style="width: 24px; height: 24px" 
        :src="`/images/login/${method.icon}`"
        fit="fill" 
      />
        &nbsp;
      {{ $t(`login.methods.${method.id}.buttonText`) }}
    </el-button>
  </div>
</template>

<script setup>
import { cloneDeep, filter, includes } from 'lodash'
import { computed, watch, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useConferenceStore } from '@/stores/conference'
import { useLoginStore } from '@/stores/login'
const conferenceStore = useConferenceStore()
const { conference } = storeToRefs(conferenceStore)
const loginStore = useLoginStore()
const selectMethod = method => loginStore.$patch({ method })

const allMethods = [
  { id: 'email', icon: 'mail.svg'},
  { id: 'google', icon: 'google.svg'}
]

const availableMethods = computed(() => filter(allMethods, method => includes(conference.value.authMethods, method.id))) 

</script>