<template>
  <div
    :style="{
      marginBottom: '1em'
    }"
  >
    <span 
      v-if="!hideLabel"
      class="el-form-item__label"
    >
      {{ $t(label) }}
    </span>
    
    <editor
      api-key="w2y88aykapau8utip1q49l7vl8o9ui2iolya1wtphcp0kvlv"
      :model-value="value"
      :label="$t(label)" 
      v-bind="$attrs" 
      :init="editorConfig" 
      @update:modelValue="updateDebounced"
    />
  </div>
</template>

<script setup>
import { get, set, split } from 'lodash'
import { computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import Editor from '@tinymce/tinymce-vue'
import { useField } from '@/composables/field'
import { useUserConferenceStore } from '@/stores/userConference'

const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  fieldName: {
    type: String,
    required: true
  },
  locale: {
    type: String,
    required: true
  },
  customLabel: {
    type: String,
    default: null
  },
  hideLabel: {
    type: Boolean,
    default: false
  }
})

const {
  store,
  fieldName,
  locale,
  customLabel
} = props

const userConference = storeToRefs(useUserConferenceStore())
const currentLocale = userConference.locale
const editorConfig = computed(() => ({ 
  height: 350,
  language: currentLocale,
  // language: split(locale, '_')[0],
  // language_url: '/js/admin/tinymce/' + language + '.js',
  entity_encoding: 'raw',
  // content_css: '/css/app.css',
  relative_urls: false,
  document_base_url: location.protocol + '//' + location.hostname + '/',
  plugins: 'print preview importcss searchreplace autolink autosave directionality visualblocks visualchars fullscreen image link media template codesample table hr pagebreak nonbreaking anchor advlist lists textpattern noneditable help charmap emoticons',
  toolbar: 'undo redo | bold italic underline strikethrough | formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | fullscreen | image media link | code'
}))

const {
  value,
  label,
  updateDebounced
} = useField({
  store,
  fieldName,
  customLabel
})

</script>
