<template>
  <el-config-provider :locale="elementLocale">
    <el-container>
      <MenuDrawer />
      <AHeader />
      <VirtualConference
        v-if="conference.isVirtual"
      />
      <ClassicConference
        v-else
      />
      <el-footer />
      <FormModal />
      <SettingsModal />
    </el-container>
  </el-config-provider>
</template>

<script setup>
import { cloneDeep } from 'lodash'
import { computed, watch, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useUserConferenceStore } from '@/stores/userConference'
import { useConferenceStore } from '@/stores/conference'
import { ElConfigProvider } from 'element-plus'
import dayjs from 'dayjs'

import AHeader from '@/layout/AHeader'
import MenuDrawer from '@/layout/MenuDrawer'
import VideoStream from '@/layout/VideoStream'

import ClassicConference from '@/layout/ClassicConference'
import VirtualConference from '@/layout/VirtualConference'
import FormModal from '@/layout/FormModal'
import SettingsModal from '@/layout/UserConference/SettingsModal'

import router from '@/router'
import { getElementLocale } from '@/functions'


// import SettingsModal from './views/UserConference/SettingsModal'

// import ApplicationBar from '@/layout/ApplicationBar'
// import ApplicationFooter from '@/layout/ApplicationFooter'
// import InPageMessages from '@/layout/InPageMessages'
// import NotificationsAlert from '@/layout/NotificationsAlert'
// import RoomSelect from '@/layout/RoomSelect'
// import PartnersSwiper from '@/layout/PartnersSwiper'
// import SmileysSwiper from '@/layout/SmileysSwiper'


const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const conferenceStore = useConferenceStore()
const { conference } = storeToRefs(conferenceStore)
const userConferenceStore = useUserConferenceStore()
const { locale } = storeToRefs(userConferenceStore)

const elementLocale = computed(() => {
  dayjs.locale(locale.value)
  return getElementLocale(locale.value)
})

const showSplash = false
const footer = {
  enabled: true
}
const isSelectRoomShowed = true
const isShowRoomModule = false
const showPartnersBar = true
const menuTitle = true
const showNotifications = true
const isInteractiveViewVisible = true
const rightContainerWidth = '100%'
const leftContainerWidth = '25%' //"75%"


const showVideo = computed(() => (conference.value.isVirtual && !(router.currentRoute.value.meta && router.currentRoute.value.meta.hideVideo)))

const visibilityIntervalUnsignRef = ref(null)

watch(conference.value, (conf) => {
  console.log({ conf })
  const { visibilityInterval } = conf
  console.log({ visibilityInterval })

  if(visibilityInterval) {

    if(visibilityIntervalUnsignRef.value) {
      clearInterval(visibilityIntervalUnsignRef.value)
    }

    visibilityIntervalUnsignRef.value = setInterval(() => {
      userStore.update({
        visibilityState: document.visibilityState
      })
    }, parseInt(visibilityInterval) * 1000)
  }
}, { deep: true, immediate: true })

// const elDialog = window.document.querySelector('.el-dialog');
// elDialog.style.setProperty('width', '90%')

</script>

<style>
  @media (max-width:768px) {
    .el-main {
      padding-left: 0;
      padding-right: 0;
    }
  }
</style>
