import { getFirestore, doc, deleteDoc } from "firebase/firestore"
import { computed, toRef, reactive } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { forEach, set, assign, debounce } from 'lodash'
import { useUserConferenceStore } from '@/stores/userConference'

export function remove({ store, options, pinia, app }) {
  const { document, fullPath } = store
  if(document) {
    store.remove = () => {
      const docToDelete = doc(getFirestore(), fullPath)
      console.log({ docToDelete })
      deleteDoc(docToDelete)
    }
  }
}

