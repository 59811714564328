import { pinia, defineStore, storeToRefs } from 'pinia'

import { computed, reactive } from 'vue'
import { filter, map, takeRight, keys, forEach, cloneDeep, mapValues, keyBy, isArray } from 'lodash'
import { collection, getFirestore, where } from "firebase/firestore"
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage"
import { useRoute, useRouter } from 'vue-router'
import { colord } from 'colord'

import { useRoomsStore } from '@/stores/rooms'
import { useSessionsStore } from '@/stores/sessions'
import { useSpeakersStore } from '@/stores/speakers'
import { useMenuItemsStore } from '@/stores/menuItems'
import { useContentPagesStore } from '@/stores/contentPages'
import { useUserStore } from '@/stores/user'
import { useConferenceUserStore } from '@/stores/conferenceUser'
import fields from '@/../functions/common/fields/conference'
import router from '@/router'

const styleVars = {
  '--el-font-family': 'Roboto,sans-serif',
  '--el-dialog-width': '90%'
}

const colorBrightness = {
          'light-1': 0.9,
          'light-3': 0.7,
          'light-5': 0.5,
          'light-7': 0.3,
          'light-8': 0.2,
          'light-9': 0.1,
          'dark-2': -0.2
        }

export const useConferenceStore = defineStore('conference', {
    state: () => ({
      document: reactive({}),
      fullPath: null,
      conferenceUserId: null,
      files: []
    }),
    getters: {
      conference: state => state.document,
      conferenceUser: state => {
        const { conferenceUserId } = state
        if(conferenceUserId) {
          const { conferenceUser } = storeToRefs(useConferenceUserStore(conferenceUserId)) 
          return conferenceUser.value
        }
      },
      fields: state => fields,
      conferenceId: state => state.document.id,
      loginConferenceId: state => (state.document.id && state.document.id === 'default') ? '' : state.document.id,
      fileUrls: state => mapValues(keyBy(state.files, 'path'), 'url')
    },
    actions: {
      async loadConference(conferenceId) {
        const fullPath = `/conferences/${conferenceId}`
        await this.syncDocument(fullPath)
        await this.loadConferenceFiles(conferenceId)
        
        const root = document.querySelector(':root');

        forEach(keys(this.document.theme.colors), colorName => {
          const colorValue = colord(this.document.theme.colors[colorName])
          forEach(colorBrightness, (percent, name) => {
            const newColorName = `${colorName}-${name}`
            const newColorValue = colorValue.alpha(percent).toHex()
            root.style.setProperty(newColorName, newColorValue)
            const newcolor = getComputedStyle(root).getPropertyValue(newColorName)
          })
          root.style.setProperty(colorName, colorValue.toHex())
        })

        forEach(styleVars, (value, key) => {
          root.style.setProperty(key, value)
        })

        return this.document
      },

      async loadConferenceAssets(conferenceId, conferenceUserId) {
        const fullPath = `/conferences/${conferenceId}`
        const childPath = function(path) {
          return `${fullPath}${path}`
        }

        const sessionsStore = useSessionsStore()
        const roomsStore = useRoomsStore()
        const speakersStore = useSpeakersStore()
        const menuItemsStore = useMenuItemsStore()
        const contentPagesStore = useContentPagesStore()

        if(conferenceUserId) {
          this.conferenceUserId = conferenceUserId
          const { conferenceUser } = storeToRefs(useConferenceUserStore(conferenceUserId, `conferences/${conferenceId}/conferenceUsers`))
          const badges = computed(() => conferenceUser.value.badges)
          if(isArray(badges.value) && badges.value.length) {
            sessionsStore.initCollection(childPath('/sessions'), where('badges', 'array-contains-any', badges.value))
            menuItemsStore.initCollection(childPath('/menuItems'), where('badges', 'array-contains-any', badges.value))
          }
        }

        sessionsStore.initCollection(childPath('/sessions'), where('badges', '==', []))
        roomsStore.initCollection(childPath('/rooms'))
        speakersStore.initCollection(childPath('/speakers'))
        menuItemsStore.initCollection(childPath('/menuItems'), where('badges', '==', []))
        contentPagesStore.initCollection(childPath('/contentPages'))
      },


      async loadConferenceFiles(conferenceId) {
        const path = `/conferences/${conferenceId}`
        const storageRef = await ref(getStorage(), path)
        const filesRefs = await listAll(storageRef)
        console.log({ filesRefs })
        const files = await Promise.all(map(filesRefs.items, async fileRef => {
          const url = await getDownloadURL(fileRef)
          const pathParts = fileRef._location.path_.split('/')
          const path = takeRight(pathParts, pathParts.length - 2).join('/')
          return {
            path,
            url,
            ref: fileRef
          }
        }))
        // console.log({ files })
        this.files = files
      }
    },
  document: true
})