import { defineStore, storeToRefs } from 'pinia'
import { computed, reactive, ref, toRef } from 'vue'
import { filter, mapValues, includes } from 'lodash'
import { getFirestore, setDoc, doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore"
import { useUserStore } from '@/stores/user'
import { useConferenceStore } from '@/stores/conference'

import fields from '@/../functions/common/fields/userConference'

export const useUserConferenceStore = (conferenceId = null, path = null) => defineStore(`userConference`, {
    state: () => ({
      document: reactive({}),
      fullPath: `${path}/${conferenceId}`,
      editingStore: ref(null),
      showMenuDrawer: ref(false),
      showSettingsDialog: ref(false),  
      displayAdmin: ref(true),  
      settingsTab: ref(0)  
    }),
    getters: {
      fields: state => fields,
      userConference: state => state.document,
      conferenceId: state => state.document.id,
      currentRoomId: state => state.document.currentRoomId,
      isAdmin: state => {
        const { isAdmin } = storeToRefs(useUserStore())
        return isAdmin
      },
      showAdmin: state => {
        const { isAdmin } = storeToRefs(useUserStore()) 
        const { displayAdmin } = state
        return isAdmin.value && displayAdmin 
      },
      currentLocale: state => {
        const { currentLocale } = state.document
        const { conference } = storeToRefs(useConferenceStore()) 
        return currentLocale || conference.value.locale
      },
      locale: state => state.currentLocale
    },
    document: true,
    actions: {
      toggleFavoriteSessions(id) {
        if(includes(this.document.favoriteSessions, id)) {
          updateDoc(doc(getFirestore(), this.fullPath), {
            favoriteSessions: arrayRemove(id)
          }) 
        } else {
          updateDoc(doc(getFirestore(), this.fullPath), {
            favoriteSessions: arrayUnion(id)
          })
        }
      },
      updateStarredSessions(id, stars) {
        console.debug(id, stars)
        updateDoc(doc(getFirestore(), this.fullPath), {
          [`starredSessions.${id}`]: stars
        }) 
      }
    }
  }
)()