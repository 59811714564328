<template>
  <el-dropdown>
    <span class="el-dropdown-link">
      <el-icon
        size="20"
        :style="{
          cursor: 'pointer'
        }"
      >
        <Operation />
        <template #title>{{ userConference.conferenceId }}</template>
      </el-icon>
    </span>

    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          @click="toggleAdmin"
        >
          {{ displayAdmin ? $t('conference.admin.menu.showAsUser') : $t('conference.admin.menu.showAsAdmin') }}
        </el-dropdown-item>

        <el-dropdown-item divided />
        <el-dropdown-item
          @click="navigateTo"
        >
          {{ $t('conference.admin.menu.settings') }}
        </el-dropdown-item>

        <el-dropdown-item divided />
        <AdminItem
          v-for="item in modules"
          :id="item.id"
          :key="item.id" 
        />
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import { ref, computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import AdminItem from './AdminItem'
import { useUserConferenceStore } from '@/stores/userConference'
import router from '@/router'

const userConferenceStore = useUserConferenceStore()  
const { userConference, conferenceId, displayAdmin, route } = storeToRefs(userConferenceStore)


const modules = [
  { id: 'conferenceUsers' },
  { id: 'rooms' },
  { id: 'contentPages' }
]

const navigateTo = () => {
  console.log({ conferenceId })
  router.push({ path: `/${conferenceId.value}/admin/general` })
}

const toggleAdmin = () => userConferenceStore.$patch({ 
  displayAdmin: !displayAdmin.value
})
</script>
