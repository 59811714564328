import { defineStore, acceptHMRUpdate, storeToRefs } from 'pinia'
import { computed, reactive } from 'vue'
import { filter, keys, includes, get, some, map } from 'lodash'
import { collection, getFirestore } from "firebase/firestore"
import { striptags } from '@/filters'
import router from '@/router'
import { useRoomsStore } from './rooms'
import { useSessionsStore } from './sessions'
import { useSpeakersStore } from './speakers'
import { useConferenceStore } from './conference'
import { useUserConferenceStore } from './userConference'
import { useUserStore } from './user'
import fields from '@/../functions/common/fields/session'
import form from '@/views/Sessions/SessionForm' 

export const searchedSessionFilterFn = session => {
  const { locale, userConference } = storeToRefs(useUserConferenceStore())
  const userStore = useUserStore()
  const { userLocale } = storeToRefs(userStore)

  const { searchSessions } = userConference.value
  

  const sessionName = get(session, `name.${userLocale.value}`) || ''
  const foundSessionName = sessionName.toLowerCase().indexOf(searchSessions.toLowerCase()) !== -1
  if(foundSessionName) {
    console.log(session.name.cs, sessionName)
    return true
  }



  // TODO pridat podminku na zobrazeni CODE
  // (session.code && session.code.toLowerCase() == searchSessions.toLowerCase()) ||
  const { speakers } = storeToRefs(useSpeakersStore())
  const sessionSpeakers = filter(speakers.value, speaker => includes(session.speakers, speaker.id))
  const foundSpeakers = filter(sessionSpeakers, speaker => {
    const speakerName = `${speaker.firstname || ''} ${speaker.surname || ''}`
    const speakerNameHasSearch = speakerName.toLowerCase().indexOf(searchSessions.toLowerCase()) !== -1
    if(speakerNameHasSearch) {
      console.log(session.name.cs, speakerName)
      return true 
    }
    /*if(speakerSubtitleHasSearch) {
    const subtitle = get(speaker, `subtitle.${userLocale.value}`)
    // const speakerSubtitleHasSearch = subtitle ? subtitle.toLowerCase().indexOf(searchSessions.toLowerCase()) !== -1 : false
      console.log(sessionName, 'speakerSubtitleHasSearch')
      return true
    }*/
    return false
  })

  if(foundSpeakers.length) {
    console.log(sessionName, map(foundSpeakers, speaker => `${speaker.firstname || ''} ${speaker.surname || ''}`))
    return true

  }
}

export const useSessionStore = (sessionId, path = null) => defineStore(`sessions/${sessionId}`, {
    state: () => ({
      document: reactive({}),
      fullPath: `${path}/${sessionId}`,
      form
    }),
    getters: {
      fields: state => fields,
      session: state => state.document,
      hasSpeakers: state => {
        if (state.speakers)
          return state.speakers.length > 0
        return false
      },
      subSessions: state => {
        const sessionsStore = useSessionsStore()
        return filter(sessionsStore.documents, { parentId: sessionId })     
        return []
      },
      searchedSubSessions: state => {
        const { userConference } = storeToRefs(useUserConferenceStore()) 
        const { searchSessions } = userConference.value
        if(searchSessions) {
          return filter(state.subSessions, searchedSessionFilterFn)
        }
        return state.subSessions
      },
      stars: state => {
        const userConferenceStore = useUserConferenceStore()
        return userConferenceStore.document.starredSessions ? userConferenceStore.document.starredSessions[state.document.id] : null
      },
      isFavorite: state => {
        const userConferenceStore = useUserConferenceStore()
        return includes(userConferenceStore.document.favoriteSessions, state.document.id)
      },
      hasAbstract: state => striptags(state.abstract).trim() != '',
      sortedSpeakers: state => {
        if (!state.speakers) return []
        return _.orderBy(state.speakers, ['pivot.position'], ['asc'])
      }
    },
    actions: {
      getRoomName(roomId) {
        const { documents } = storeToRefs(useRoomsStore())
        const found = get(documents.value, roomId)
        return found ? found.name : roomId
      },
      goToQuestions() {
        const { conferenceId } = storeToRefs(useUserConferenceStore())
        const sessionId = this.document.id
        router.push({ name: 'sessionQuestions', params: { conferenceId: conferenceId.value, sessionId }})
      }
    },

    document: true 
  }
)()