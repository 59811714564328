<template>
  <div>
    <el-tag
      class="mr-2"
      type="primary"
      :title="$t('common.adminControls.visibleCount')"
    >
      {{ visibleCount }}
    </el-tag>
    <el-tag
      class="mr-2"
      type="info"
      :title="$t('common.adminControls.hiddenCount')"
    >
      {{ hiddenCount }}
    </el-tag>
  </div>
</template>

<script setup>
import { forEach } from 'lodash'
import { ref, computed, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { getFirestore, getDocs, getCountFromServer, limit, collection, query, where } from 'firebase/firestore'
import { useConferenceStore } from '@/stores/conference'

const { conference } = storeToRefs(useConferenceStore())

const hiddenCount = ref(0)
const visibleCount = ref(0)

const countQuery = visibilityState => query(collection(getFirestore(), 'users'),/* where('lastConnection', '<', new Date()), */where('visibilityState', '==', visibilityState), where('currentConferenceId', '==', conference.value.id))

const visibilityIntervalUnsignRef = ref(null)

watch(conference.value, (conf) => {
  console.log({ conf })
  const { visibilityInterval } = conf

  if(visibilityInterval) {

    if(visibilityIntervalUnsignRef.value) {
      clearInterval(visibilityIntervalUnsignRef.value)
    }

    visibilityIntervalUnsignRef.value = setInterval(async() => {
      const hiddenSnapshot = await getCountFromServer(countQuery('hidden'))
      const hidden = hiddenSnapshot.data().count
      hiddenCount.value = hidden

      const visibleSnapshot = await getCountFromServer(countQuery('visible'))
      const visible = visibleSnapshot.data().count
      visibleCount.value = visible 
    }, (parseInt(visibilityInterval) + 5) * 1000)
  }
}, { deep: true, immediate: true })

</script>
