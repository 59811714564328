<template>
  <component
    :is="tag"
    v-html="localizedValue"
  />
</template>

<script setup>
import { computed, ref, reactive, toRefs } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { get, set } from 'lodash'

import { useUserStore } from '@/stores/user'
import { useUserConferenceStore } from '@/stores/userConference'
import { useField } from '@/composables/field'

const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  fieldName: {
    type: String,
    required: true
  },
  tag: {
    type: String,
    default: 'span'
  }
})

const {
  store,
  fieldName
} = props

const { locale } = storeToRefs(useUserConferenceStore())
const { userLocale } = storeToRefs(useUserStore())

const {
  value
} = useField({
  store,
  fieldName
})

const localizedValue = computed(() => get(value.value, userLocale.value))
</script>
