import { find, split } from 'lodash'
import { v4 as random } from 'uuid'
import { computed, ref } from 'vue'
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"

export function useFile({
  path,
  fileName = '',
  size = '',
  suffix = ''
} = {}) {
  
  const fullPath = `${path}${fileName}${size ? ('_' + size) : ''}${suffix}.png`
  const basePath = `${path}${fileName}${suffix}`

  // const file = computed(() => find(store.files, { path: fullPath }))

  const file = storageRef(getStorage(), fullPath)

  let progress = ref(null)
  let url = ref(null)
  let key = ref(random())


  const upload = (fileToUpload) => {
    let extension = 'png' 
    switch(fileToUpload.raw.type) {
      case 'image/jpeg':
        extension = 'jpeg'
        break
    }
    const baseRef = storageRef(getStorage(), `${basePath}.${extension}`)
    console.log({ fileToUpload })
    const uploadTask = uploadBytes(baseRef, fileToUpload.raw)

    uploadTask.then(
      (success) => {
        console.log({ uploadTask })
        getDownloadURL(file).then((newUrl) => url.value = newUrl)    
        key.value = random()
        console.log('key', key.value)    
      },
      (uploadError) => {
        console.log({ uploadError })
      } 
    ); 
  }
  
  getDownloadURL(file).then((newUrl) => url.value = newUrl)

  return {
    key,
    file,
    url,
    upload,
    progress
  }
}