<template>
  <div>
    <div
      v-if="!status"
    >
      <el-space fill>
        <el-alert
          type="info"
          show-icon
          :closable="false"
        >
          <p>{{ $t('login.methods.email.enterYourEmailDescription') }}</p>
        </el-alert>
        <el-form-item>
          <el-input
            v-model="email"
            type="email"
            :pattern="emailRegex"
            required
            :placeholder="$t('login.methods.email.enterYourEmail')"
          />
        </el-form-item>
        <el-button
          type="primary"
          @click="sendLinkToEmail"
        >
          {{ $t('login.methods.email.sendLinkToEmail') }}
        </el-button>
      </el-space>
    </div>

    <el-alert
      v-if="status === 'sent'"
      :title="$t('login.methods.email.linkSentToEmail')" 
      type="success" 
    />
  </div>
</template>

<script setup>
import { cloneDeep } from 'lodash'
import { computed, watch, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useConferenceStore } from '@/stores/conference'
import { useLoginStore } from '@/stores/login'
import { useI18n } from 'vue-i18n'
const conferenceStore = useConferenceStore()
const { conference, conferenceId } = storeToRefs(conferenceStore)
import { firebaseAuth } from "@/firebase"
import { sendSignInLinkToEmail } from "firebase/auth"
const loginStore = useLoginStore()
const { status } = storeToRefs(loginStore)
const { t } = useI18n()

const email = ref('')

const emailRegex = /^\S+@\S+\.\S+$/

const url = computed(() => `https://ff.easyevent.cz/${conferenceId.value}/email/${email.value}`)

const sendLinkToEmail = () => {
  if(emailRegex.test(email.value)) {
    sendSignInLinkToEmail(firebaseAuth, email.value, {
      url: url.value,
      handleCodeInApp: true
    })
      .then(() => {
        loginStore.$patch({ status: 'sent' })
      })
      .catch((error) => {
        console.error({ error })
      })
  } else {
    alert(t('login.methods.email.enterValidEmail'))
  }
}

</script>