import { getFirestore, onSnapshot, setDoc, doc, collection, collectionGroup, query, where } from "firebase/firestore"
import { values, map } from 'lodash'
import { defineStore } from 'pinia'
import { useConferenceItemStore } from '@/stores/conferenceItem'
import fields from '@/../functions/common/fields/conference'


export const useAdminConferencesStore = defineStore(`adminConferences`, {
  getters: {
  	fields: state => fields,
  	adminConferences: state => values(state.documents),
  	hasAdminConferences: state => values(state.documents).length

  },
	actions: {
		useChildStore: (id, path) => useConferenceItemStore(id, path),
		createConference: async (params) => await setDoc(doc(getFirestore(), 'conferences', params.id), params, { merge: true })
	}
})