import { values, map } from 'lodash'
import { defineStore } from 'pinia'
import { useLocaleStore } from '@/stores/locale'


export const useLocalesStore = defineStore(`locales`, {
  getters: {
  	messages: state => values(state.documents)
  },
  actions: {
		useChildStore: (id, path) => useLocaleStore(id, path)
	}
})