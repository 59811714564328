const type = [
  'standard',
  'special',
  'break',
  'lunch',
  'party',
  'freetime'
]

module.exports = {
  type
}
