module.exports = [
  { name: 'id' },
  { name: 'conferenceId' },
  { name: 'parentId' },
  { name: 'icon' },
  { name: 'enabled', default: true },
  { name: 'moduleName' },
  { name: 'moduleConfig', default: {} },
  { name: 'position' },
  
  { name: 'name', localized: true }, 
  { name: 'badges', default: []},

]
