<template>
  <el-main>
    <el-row 
      :gutter="10"
    >
      <el-col 
        v-if="showVideo"
        :xs="24"
        :sm="24"
        :md="16"
      >
        <VideoStream />
      </el-col>
          
      <el-col
        :xs="24"
        :sm="24"
        :md="showVideo ? 8 : 24"
        :style="{ display: 'flex'}"
      >
        <!-- <Menu
              class="hidden-sm-and-down"
            /> -->
        <router-view
          :key="$route.fullPath"
          :style="{ overflow: 'hidden' }"
        />
      </el-col>
    </el-row>
  </el-main>
</template>

<script setup>
import { cloneDeep } from 'lodash'
import { computed, watch, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useUserConferenceStore } from '@/stores/userConference'
import { useConferenceStore } from '@/stores/conference'
import { ElConfigProvider } from 'element-plus'
import dayjs from 'dayjs'

import AHeader from '@/layout/AHeader'
import MenuDrawer from '@/layout/MenuDrawer'
import VideoStream from '@/layout/VideoStream'

import ClassicConference from '@/layout/ClassicConference'
import VirtualConference from '@/layout/VirtualConference'
import FormModal from '@/layout/FormModal'
import SettingsModal from '@/layout/UserConference/SettingsModal'

import router from '@/router'
import { getElementLocale } from '@/functions'


// import SettingsModal from './views/UserConference/SettingsModal'

// import ApplicationBar from '@/layout/ApplicationBar'
// import ApplicationFooter from '@/layout/ApplicationFooter'
// import InPageMessages from '@/layout/InPageMessages'
// import NotificationsAlert from '@/layout/NotificationsAlert'
// import RoomSelect from '@/layout/RoomSelect'
// import PartnersSwiper from '@/layout/PartnersSwiper'
// import SmileysSwiper from '@/layout/SmileysSwiper'


const userStore = useUserStore()
const { user } = storeToRefs(userStore)
const conferenceStore = useConferenceStore()
const { conference } = storeToRefs(conferenceStore)
const userConferenceStore = useUserConferenceStore()
const { locale } = storeToRefs(userConferenceStore)

const elementLocale = computed(() => {
  dayjs.locale(locale.value)
  return getElementLocale(locale.value)
})

const showSplash = false
const footer = {
  enabled: true
}
const isSelectRoomShowed = true
const isShowRoomModule = false
const showPartnersBar = true
const menuTitle = true
const showNotifications = true
const isInteractiveViewVisible = true
const rightContainerWidth = '100%'
const leftContainerWidth = '25%' //"75%"


const showVideo = computed(() => (conference.value.isVirtual && !(router.currentRoute.value.meta && router.currentRoute.value.meta.hideSidebar)))

const visibilityIntervalUnsignRef = ref(null)

</script>

<style>
  @media (max-width:768px) {
    .el-main {
      padding-left: 0;
      padding-right: 0;
    }
  }
</style>
