<template>
  <div>
    <AInput
      :store="menuItemStore"
      field-name="moduleConfig.url"
    />
  </div>
</template>

<script setup>
import { defineStore, storeToRefs } from 'pinia'
import { useMenuItemStore } from '@/stores/menuItem'
import AInput from '@/components/AInput'

const props = defineProps({
  menuItemId: {
    type: String,
    required: true
  }
})

const { menuItemId } = props
const menuItemStore = useMenuItemStore(menuItemId)
const { 
  menuItem
} = storeToRefs(menuItemStore)

</script>