<template>
  <div
    v-if="props.showing"
    :model-value="true"
    max-width="290"
  >
    <span>
      {{ $t('components.formModal.confirmRemove.proceed') }} 
    </span>

    <el-button
      @click="cancel"
    >
      {{ $t('common.back') }} 
    </el-button>

    <el-button
      type="danger"
      @click="confirm"
    >
      {{ $t('common.delete') }} 
    </el-button>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue'

const props = defineProps({
  showing: {
    type: Boolean,
    required: true
  }
})

const { showing } = props

const emit = defineEmits(['confirm', 'cancel'])

const cancel = () => {
  emit('cancel')
}

const confirm = () => {
  emit('confirm')
}


</script>