<template>
  <div>
    <AUpload
      :store="speakerStore"
      :path="`conferences/${conferenceId}/`"
      size="200x200"
    />

    <AInput
      :store="speakerStore"
      field-name="degreeBefore"
    />

    <AInput
      :store="speakerStore"
      field-name="firstname"
    />

    <AInput
      :store="speakerStore"
      field-name="surname"
    />

    <AInput
      :store="speakerStore"
      field-name="degreeAfter"
    />

    <LocalizedInput
      :store="speakerStore"
      field-name="subtitle"
    />

    <LocalizedInput
      :store="speakerStore"
      field-name="bio"
      is-editor
    />

    <AInput
      :store="speakerStore"
      field-name="email"
    />

    <AInput
      :store="speakerStore"
      field-name="phone"
    />

    <AInput
      :store="speakerStore"
      field-name="address"
    />
  </div>
</template>

<script setup>
import { values } from 'lodash' 
import { storeToRefs } from 'pinia'

import { useSpeakerStore } from '@/stores/speaker'
import { useUserConferenceStore } from '@/stores/userConference'

import LocalizedValue from '@/components/LocalizedValue'
import LocalizedInput from '@/components/LocalizedInput'
import AInput from '@/components/AInput'
import ASelect from '@/components/ASelect'
import ADateTimePicker from '@/components/ADateTimePicker'
import AUpload from '@/components/AUpload'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})
const { id } = props

const speakerStore = useSpeakerStore(id)
const  { speaker } = storeToRefs(speakerStore)
const userConferenceStore = useUserConferenceStore()
const { locale, conferenceId } = storeToRefs(userConferenceStore)
</script>
