module.exports = [
  { name: 'id' },
  { name: 'conferenceId' },

  { name: 'name' }, 
  { name: 'surname' }, 
  { name: 'degreeBefore' }, 
  { name: 'degreeAfter' }, 
  { name: 'email' }, 
  { name: 'phone' }, 
  { name: 'address' }, 
  { name: 'type' }, 
  { name: 'public' }, 
  
  { name: 'subtitle', localized: true }, 
  { name: 'bio', localized: true }, 
]
