<template>
  <el-form-item 
    :label="$t(label)"
  >
    <el-select
      v-bind="$attrs"
      :model-value="value" 
      :placeholder="$t('components.aSelect.placeholder')"
      filterable
      clearable
      :filter-method="setFilter"
      @focus="setFilter('')"
      @clear="setFilter('')"
      @update:modelValue="update"
    >
      <el-option
        v-for="icon in icons"
        :key="icon"
        :label="icon"
        :value="icon"
      >
        <font-awesome-icon 
          :icon="`fa-solid fa-${icon}`" 
        />
        {{ icon }}
      </el-option>
    </el-select>
  </el-form-item>
</template>

<script setup>
import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { find, set, get, clone, indexOf, concat, map } from 'lodash'
import { useField } from '@/composables/field'
import { fas } from '../icons'

const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  fieldName: {
    type: String,
    required: true
  },
  items: {
    type: Array,
    required: false
  },
  nullValue: {
    type: Boolean,
    default: false
  },
  customLabel: {
    type: String,
    default: null
  }
})


const {
  store,
  fieldName,
  nullValue,
  customLabel
} = props

const {
  value,
  label,
  update,
  fieldOptions
} = useField({
  store,
  fieldName,
  customLabel
})

const filter = ref('')

const preferred = [
  "user",
  "users",
  "chalkboard-teacher",
  "clock",
  "list",
  "comment",
  "comments",
  "bullhorn",
  "handshake",
  "envelope",
  "arrows-alt-h",
  "info-circle",
  "download",
  "layer-group",
  "trophy",
  "star"
]

const allIcons = computed(() => concat(preferred, map(fas, 'iconName')))

const icons = computed(() => {
  let icons = allIcons.value

  if (filter.value) {
    icons = icons.filter(item => item.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)
  }

  return icons

})

const setFilter = value => filter.value = value
</script>
