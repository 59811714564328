import { defineStore } from 'pinia'
import { collection, getFirestore } from "firebase/firestore"
import { groupBy, filter, map, values } from 'lodash'
import dayjs from 'dayjs'
import { useRoomStore } from '@/stores/room'
import fields from '@/../functions/common/fields/room'

export const useRoomsStore = defineStore(`rooms`, {
  getters: {
  	fields: state => fields,
  	rooms: state => values(state.documents),
  	currentRoom : state => {
  		return null
  		const { roomId } = state.route.params
  		// console.log({ roomId })
  		return roomId ? filter(state.documents, { id: roomId }) : null
  	}
	},
	actions: {
		useChildStore: (id, path) => useRoomStore(id, path)
	}
})