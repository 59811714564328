import { reactive } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { filter, includes } from 'lodash'
import { collection, getFirestore, getDocs, limit, query, where } from "firebase/firestore"
import { useUserConferenceStore } from './userConference'
import { useConferenceStore } from './conference'
import { useConferenceUserStore } from './conferenceUser'
import router from '@/router'
const { isReady } = 'vue-router'
import fields from '@/../functions/common/fields/user'

export const useUserStore = auth => defineStore(`user`, {
    state: () => ({
      document: reactive({}),
      auth,
      conferenceUserId: null,
      fullPath: `users/${auth.uid}`,
      isSuperAdmin: false
    }),
    getters: {
      fields: state => fields,
      user: state => state.document,  
      conferenceUser: state => {
        const { conferenceUserId } = state
        if(conferenceUserId) {
          const { conferenceUser } = storeToRefs(useConferenceUserStore(conferenceUserId)) 
          return conferenceUser.value
        } else {
          return null
        }
      },
      userConference: state => {
        const { userConference } = storeToRefs(useUserConferenceStore()) 
        return userConference.value
      },
      isAdmin: state => {
        const { isSuperAdmin } = state
        const { conference } = storeToRefs(useConferenceStore()) 
        return isSuperAdmin || includes(conference.value.administrators, state.auth.uid)
      },
      isSpeaker: state => {
        const { conference } = storeToRefs(useConferenceStore()) 
        return includes(conference.value.speakers, state.auth.uid)
      },
      isUser: state => {
        const { conference } = storeToRefs(useConferenceStore()) 
        return conference.value.isOpen || includes(conference.value.users, state.auth.uid)
      },
      userLocale: state => {
        const { conferenceUser, userConference, user } = state
        const userConferenceLocale = userConference ? userConference.currentLocale : '' 
        const userLocale = user ? user.currentLocale : '' 
        const conferenceUserLocale = conferenceUser ? conferenceUser.language : ''
        const preferredLocale = userConferenceLocale || userLocale || conferenceUserLocale
        const { conference } = storeToRefs(useConferenceStore()) 
        if(preferredLocale && includes(conference.value.availableLocales, preferredLocale)) {
        console.log({ preferredLocale })
          return preferredLocale
        } else {
        console.log('defaultLocale', conference.value.defaultLocale)
          return conference.value.defaultLocale
        }
      }
    },
    actions: {
      setSuperUser() {
        this.isSuperAdmin = true
      },
      async loadConferenceSettings(conferenceId) {
        return new Promise(async (resolve, reject) => {
          let conferenceUserId
          try {
            const userId = this.auth.uid
            const userConferenceStore = useUserConferenceStore(conferenceId, `${this.fullPath}/userConferences`)
            await userConferenceStore.syncDocument(`${this.fullPath}/userConferences/${conferenceId}`)
     
            const conferenceUsersRef = collection(getFirestore(), `conferences/${conferenceId}/conferenceUsers`)
            const existingConferenceUserRef = await getDocs(query(conferenceUsersRef, where('uid', '==', userId), limit(1)))
            console.log({ existingConferenceUserRef })
            if(!existingConferenceUserRef.empty) {
              const user = existingConferenceUserRef.docs[0].data()
              conferenceUserId = user.id
              console.log({ conferenceUserId })
              if(conferenceUserId) {
                this.conferenceUserId = conferenceUserId
              }
              const conferenceUserStore = useConferenceUserStore(conferenceUserId, `conferences/${conferenceId}/conferenceUsers`)
              await conferenceUserStore.syncDocument(`conferences/${conferenceId}/conferenceUsers/${conferenceUserId}`)
            } else {
              console.error('empty')
            }
           } catch(e) {
            console.error({ e })
           }
          return resolve(conferenceUserId)
        })
      },
      async checkConference() {
        const conferenceStore = useConferenceStore()
        const { conference } = storeToRefs(conferenceStore)
        const { conferenceId } = this.router.currentRoute.params
        const { id, currentConferenceId } = this.document

        if(!conferenceId && currentConferenceId) {
          location.href = `${location.origin}/${currentConferenceId}`
        }

        if(conferenceId && (currentConferenceId !== conferenceId)) {
          this.update({ currentConferenceId: conferenceId })
        }

        console.log({ conferenceId })
        
        if(conferenceId) {
          let conferenceUserId
          const fullPath = `/conferences/${conferenceId}`
          await conferenceStore.syncDocument(fullPath)
          // const { conference } = storeToRefs(conferenceStore)
          // console.log('{ conference.id }', conference.value.id)
          await conferenceStore.loadConference(conferenceId)
            if(this.isUser || this.isAdmin) {
              conferenceUserId = await this.loadConferenceSettings(conferenceId)
              console.log({ conferenceUserId })
              await conferenceStore.loadConferenceAssets(conferenceId, conferenceUserId)
            }
        } else {
          this.router.push({ name: 'selectConference'})
        }            
    }
  },
  document: true
})()