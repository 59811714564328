<template>
  <div 
    :class="isFloating ? 'floating-button-wrapper' : ''"
  >
    <el-button
      v-if="canCreate"
      type="success" 
      :icon="Plus" 
      circle
      :class="isFloating ? 'floating-button' : ''"
      :title="$t(`${store.$id}.create`)"
      @click="create"
    />
  </div>
</template>

<script setup>
import { Plus } from '@element-plus/icons-vue'
import { useConferenceStore } from '@/stores/conference'
import { storeToRefs } from 'pinia'

const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  isFloating: {
    type: Boolean,
    default: false
  }
})

const {
  store
} = props

const { conference } = storeToRefs(useConferenceStore())

const canCreate = true
const create = () => store.createDocument({
  conferenceId: conference.value.id,
  badges: []
})
</script>

<style scoped>
  @media print {
    .no-print {
      display: none !important;
    }
  }

  .floating-button-wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  .floating-button {
  }
</style>
