<template>
  <el-form-item 
    :label="$t(label)"
  >
    <el-select 
      v-bind="$attrs" 
      v-model="value"
      :model-value="value" 
      :placeholder="$t('components.aSelect.placeholder')"
      @update:modelValue="update"
    >
      <el-option
        v-for="item in computedItems"
        :key="optionValue(item)"
        :value="optionValue(item)"
        :label="optionTitle(item)"
      />
    </el-select>
  </el-form-item>
</template>

<script setup>
import { computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { find, set, get, clone, isFunction } from 'lodash'
import { useField } from '@/composables/field'
import { useUserConferenceStore } from '@/stores/userConference'

const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  fieldName: {
    type: String,
    required: true
  },
  items: {
    type: Array,
    required: false
  },
  nullValue: {
    type: Boolean,
    default: false
  },
  customLabel: {
    type: String,
    default: null
  },
  itemValue: {
    type: String,
    default: 'id'
  },
  itemTitle: {
    type: String,
    default: 'name'
  },
  localizedItemTitle: {
    type: String,
    required: false
  }
})

const {
  store,
  fieldName,
  items,
  nullValue,
  customLabel,
  itemValue,
  itemTitle,
  localizedItemTitle
} = props

const {
  value,
  label,
  update,
  fieldOptions
} = useField({
  store,
  fieldName,
  customLabel
})

const { userConference } = storeToRefs(useUserConferenceStore())

const computedItems = computed(() => {
  let itemsArray = items || fieldOptions || []
  if(nullValue) {
    itemsArray = clone(itemsArray)
    itemsArray.unshift(null)
  } 
  console.warn(fieldName, itemsArray)
  return itemsArray
})

const optionValue = option => itemValue ? get(option, itemValue) : option
const optionTitle = option => {
  if(isFunction(itemTitle)) {
    return itemTitle(option)
  }
  return localizedItemTitle ? get(option, `${localizedItemTitle}.${userConference.currentLocale}`) : get(option, itemTitle)
}

</script>
