import firebase from 'firebase/compat/app'
import "firebase/compat/auth"
import "firebase/compat/database"
import "firebase/compat/storage"
import { where } from "firebase/firestore"
import { split } from 'lodash'
import { createApp, computed } from 'vue'
import { storeToRefs } from 'pinia'
import router from './router'
import store from './store'
import loginStore from './login/store'
import ElementPlus from 'element-plus'
import elCs from 'element-plus/dist/locale/cs.mjs'
import elEn from 'element-plus/dist/locale/en.mjs'
import elDe from 'element-plus/dist/locale/de.mjs'
import 'dayjs/locale/en'
import 'dayjs/locale/cs'
import 'dayjs/locale/de'

import dayjs from 'dayjs'

import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
// import '@/assets/scss/app.scss'
import initI18n from './i18n'
import initIcons from './icons'
import login from './login'
import App from './App.vue'
import Login from './Login.vue'
import { useUserStore } from './stores/user'
import { useConferencesStore } from './stores/conferences'
import { useAdminConferencesStore } from './stores/adminConferences'
import { useConferenceStore } from './stores/conference'
import { useLocalesStore } from './stores/locales'
import { useLocaleStore } from './stores/locale'
// import { onReady } from 'vue-router'
import { callable } from '@/functions'

import { firebaseApp, firebaseAuth } from '@/firebase'
import { onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore'
import { ElLoading } from 'element-plus'
import translations from '@/translations'
import { updateDB } from '@/composables/field'

const elLocales = {
  en: elEn,
  cs: elCs,
  de: elDe
}

onAuthStateChanged(firebaseAuth, async (auth) => {
  const loading = ElLoading.service({ fullscreen: true })
  if(isSignInWithEmailLink(firebaseAuth, window.location.href)) {
    if(auth) {
      console.log('update user profile')
      const params = split(window.location.pathname, '/')
      console.log({ params })
      const conferenceId = params[1]
      const userProfileResult = await callable('updateUserProfile', {
        conferenceId,
        authType: params[2],
        authValue: params[3]
      })
      console.log({ userProfileResult })
      location.href = `${location.hostname}/${conferenceId}`
    } else {
      const loading = ElLoading.service({ fullscreen: true })
      console.log('signInWithEmailLinks')
      const params = split(window.location.pathname, '/')
      const conferenceId = params[1]
      if(params[2] === 'email') {
        await signInWithEmailLink(firebaseAuth, params[3], window.location.href)
      }
    }
  } else {
    if(auth) {
      const app = createApp(App)
        .use(store)
        .use(router)

      initIcons({ app })  

      await router.isReady()

      const userStore = useUserStore(auth)
      const userDocRef = doc(getFirestore(), 'users', auth.uid)
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        setDoc(doc(getFirestore(), `users/${auth.uid}`), {}, { merge: true })
      }
      await userStore.syncDocument(`/users/${auth.uid}`)
      const idToken = await auth.getIdTokenResult(true)
      if(idToken.claims.isSuperAdmin) {
        userStore.setSuperUser() 
      }
      await userStore.checkConference()
      
      const conferencesStore = useConferencesStore()
      await conferencesStore.initCollection('/conferences', where('users', 'array-contains', auth.uid))
      await conferencesStore.initCollection('/conferences', where('isOpen', '==', true))

      const adminConferencesStore = useAdminConferencesStore()
      if(idToken.claims.isSuperAdmin) {
        await adminConferencesStore.initCollection('/conferences')
      } else {
        await adminConferencesStore.initCollection('/conferences', where('administrators', 'array-contains', auth.uid))
      }
      
      const conferenceStore = useConferenceStore()
      const { conference } = storeToRefs(conferenceStore)

      const localesStore = useLocalesStore()
      await localesStore.initCollection('/locales')
      const { messages } = storeToRefs(localesStore)

      const enLocaleStore = useLocaleStore('en')
      await enLocaleStore.syncDocument(`/locales/en`)
      const en = storeToRefs(enLocaleStore).document
      
      const csLocaleStore = useLocaleStore('cs')
      await csLocaleStore.syncDocument(`/locales/cs`)
      const cs = storeToRefs(csLocaleStore).document
      
      // updateDB({ data: translations, fullPath: 'locales/en'})

      const { userLocale } = storeToRefs(userStore)

      const i18n = initI18n({ en, cs }, userLocale.value)
      const elLocale = elLocales[userLocale.value]

      loading.close()
      app
        .use(i18n)
        .use(ElementPlus, {
          locale: elCs
        })
        .mount('#app')

        console.log({ i18n })
    } else {
      // document.getElementById("app").innerHTML = ''
      console.log('starting login ui')
      const app = createApp(Login)
        .use(loginStore)

      initIcons({ app })  

      const params = split(window.location.pathname, '/')
      const conferenceId = params[1] || 'default'
      const conferenceStore = useConferenceStore()
      await conferenceStore.loadConference(conferenceId)
      const { conference } = storeToRefs(conferenceStore)
      
      const localesStore = useLocalesStore()
      await localesStore.initCollection('/locales')
      const { messages } = storeToRefs(localesStore)

      
      const enLocaleStore = useLocaleStore('en')
      await enLocaleStore.syncDocument(`/locales/en`)
      const en = storeToRefs(enLocaleStore).document


      const csLocaleStore = useLocaleStore('cs')
      await csLocaleStore.syncDocument(`/locales/cs`)
      const cs = storeToRefs(csLocaleStore).document
      
      const elLocale = elLocales[conference.value.locale]
      const i18n = initI18n({ en, cs }, conference.value.locale)

      loading.close()
      app
        .use(i18n)
        .use(ElementPlus, {
          locale: elLocale
        })
        .mount('#app')
    }
  }
})


