import { defineStore, acceptHMRUpdate } from 'pinia'
import { computed, reactive } from 'vue'
import { filter } from 'lodash'
import { collection, getFirestore } from "firebase/firestore"
import { striptags } from '@/filters'
import { useSessionsStore } from './sessions'
import fields from '@/../functions/common/fields/menuItem'
import form from '@/layout/MenuItems/MenuItemForm' 

export const useMenuItemStore = (menuItemId, path = null) => defineStore(`menuItems/${menuItemId}`, {
    state: () => ({
      document: reactive({}),
      fullPath: `${path}/${menuItemId}`, 
      form
    }),
    getters: {
      fields: state => fields,
      menuItem: state => state.document,
      countBadge: state => {
        switch(state.document.moduleName) {
          case 'messages':
            // TODO UNREAD MESSAGES COUNT
            return 0
            break
          case 'networking':
            // TODO UNREAD NETWORKING MESSAGES COUNT
            return 0
            break
          default:
            return 1
        }
      }
    },
    document: true
})()