import { defineStore } from 'pinia'
import { groupBy, filter, map, values } from 'lodash'
import { useMenuItemStore } from './menuItem'
import fields from '@/../functions/common/fields/menuItem'

export const useMenuItemsStore = defineStore(`menuItems`, {
  getters: {
  	fields: state => fields,
    menuItems: state => values(state.documents)
	},
	actions: {
		useChildStore: (id, path) => useMenuItemStore(id, path)
	}
})