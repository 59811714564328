import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC4Cg_jtmxbBMxCf94HpooRUajirWcJqUk",
  authDomain: "easyeventcz.firebaseapp.com",
  projectId: "easyeventcz",
  storageBucket: "easyeventcz.appspot.com",
  messagingSenderId: "296682748322",
  appId: "1:296682748322:web:f89a9278f3b168bfc9dafb"
}


const firebaseApp = initializeApp(firebaseConfig)
const firebaseAuth = getAuth(firebaseApp)

export {
	firebaseConfig,
  firebaseApp,
  firebaseAuth
}
