import { values } from 'lodash'
import { defineStore } from 'pinia'
import { useConferenceUserStore } from '@//stores/conferenceUser'
import fields from '@/../functions/common/fields/conferenceUser'


export const useConferenceUsersStore = defineStore(`conferenceUsers`, {
  getters: {
  	fields: state => fields,
  	conferenceUsers: state => values(state.documents)
  },
	actions: {
		useChildStore: (id, path) => useConferenceUserStore(id, path)
	}
})