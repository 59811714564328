import { getFirestore, onSnapshot, setDoc, doc, collection, collectionGroup, query, where, orderBy, documentId, startAt, endAt } from "firebase/firestore"
import { computed, toRef, reactive } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { forEach, set, assign, debounce, last, take, slice, join } from 'lodash'
import { v4 as uuid } from 'uuid'
import { useField } from '@/composables/field'

import { getFieldValue, assignDocument } from '@/../functions/common/classFunctions'

const createDocument = async ({
  path, 
  fields,
  params
}) => {
  const id = uuid()
  const data = assign({ 
    id,
    createdAt: new Date()
  }, params)
  
  let newDocumentData = assignDocument({
    fields: fields,
    data
  })
  
  await setDoc(doc(getFirestore(), path, data.id), newDocumentData)
  return newDocumentData
}


function syncCollectionStore({
  store,
  collectionRef, 
  path
}) {

    const documents = reactive({})
    store.$state.documents = documents
    store.$state.path = path
    store.documents = toRef(store.$state, 'documents')

    const { params } = store.route
    const { fields } = store
    
      store.createDocument = async(extraParams = {}, options = {}) => {
        const newDocument = await createDocument({
          path,
          params: assign({}, extraParams),
          fields
        })
        const { id } = newDocument
        const documentStore = store.useChildStore(id, path)
        if(!options.noEdit) {
          documentStore.edit()
        }
        documentStore.$patch(newDocument)
        documentStore.$state.document = reactive(newDocument)
        documentStore.document = toRef(documentStore.$state, 'document')
             
      }


    if (process.env.NODE_ENV === 'development') {
      store._customProperties.add('documents')
    }
    if(collectionRef) {
      return new Promise((resolve, reject) => {
          
        var resolveOnce = (resolve) => {
          resolve()
          resolveOnce = () => {}
        }

        onSnapshot(collectionRef, (snapshot) => {
        resolveOnce(resolve)
        snapshot.docChanges().forEach((change) => {
        const data = fields ? assignDocument({
          fields,
          data: change.doc.data()
        }) : change.doc.data()

        let pathParts = slice(change.doc._key.path.segments, change.doc._key.path.offset, change.doc._key.path.segments.length - 1)
        let path = '/' + join(pathParts, '/')
        const documentData = { document: data }
        const documentStore = store.useChildStore(change.doc.id, path)
        switch(change.type) {
          case 'added': 
            store.$patch({ documents: { [change.doc.id]: data}})
            documentStore.$patch(documentData)
            documentStore.$state.document = reactive(data)
            documentStore.document = toRef(documentStore.$state, 'document')
            break
          case 'modified':
            store.$patch({ documents: { [change.doc.id]: data}})
            documentStore.$patch(documentData)
            break
          case 'removed': 
            delete store.$state.documents[change.doc.id]
            break
          }
        })
      }) 

      return collectionRef
    }, err => {
      console.error({
          err,
          fullPath
        })
    }
    )
  }
}

export function sync({ store, options, pinia, app }) {
  store.initCollection = async(aPath, ...params) => {
    let collectionRef
    let path = aPath || store.$state.fullPath
      
    if(where) {
      collectionRef = query(collection(getFirestore(), path), ...params)
    } else {
      collectionRef = collection(getFirestore(), path)
    }

    syncCollectionStore({
      store,
      path,
      collectionRef
    })
  }

  store.initCollectionGroup = async(collectionGroupName, ...params) => {
    let collectionRef
    if(where) {
      collectionRef = query(collectionGroup(getFirestore(), collectionGroupName), ...params)
    } else {
      collectionRef = collectionGroup(getFirestore(), collectionGroupName)
    }
  
    syncCollectionStore({
      store,
      path: null,
      collectionRef
    })
  }

  store.init = async(path = null) => {
    syncCollectionStore({
      store,
      path: path || store.$state.fullPath,
      collectionRef: null
    })
  }

  store.syncDocument = async fullPath => {
      const { fields } = store
      const db = getFirestore()
      const documentRef = doc(db, fullPath)

      return new Promise((resolve, reject) => {
        
        var resolveOnce = (resolve) => {
          resolve()
          resolveOnce = () => {}
        }

        onSnapshot(documentRef, async (snapshot) => {
        if (snapshot.exists()) {
          const { id, ref, metadata } = snapshot
          const { hasPendingWrites } = metadata

          const data = assignDocument({
            fields,
            data: snapshot.data()
          })

          store.fullPath = fullPath
          store.$patch({ document: data })
          resolveOnce(resolve)
        } else {
          const pathParts = fullPath.split('/')
          const id = last(pathParts)
          const path = take(pathParts, pathParts.length - 1).join('/')
          console.log({ id })
          console.log({ path })
          let extraParams = { id }
          const newDocument = await createDocument({
            path,
            params: assign({}, extraParams),
            fields
          })
          resolve()
          console.error('DocumentSnapshot does NOT exist!', fullPath)
        }
      })
      }, err => console.error({
        err,
        fullPath
      }))
  }
}

export function update(params) {
  const { store, options } = params
  const { document } = options
  if(document) {
    store.update = (payload) => {
      forEach(payload, (value, fieldName) => {
        const {
          update
        } = useField({
          store,
          fieldName
        })
        update(value)
      })
    }
  }
}
