<template>
  <el-menu-item 
    :disabled="!menuItem.enabled"
    :index="index"
    @click="navigateToRoute"
  >
    <font-awesome-icon :icon="`fa-solid fa-${menuItem.icon}`" />
    &nbsp;
    &nbsp;
    <template #title>
      <LocalizedValue
        :store="menuItemStore"
        field-name="name"
      />

      <sup 
        v-if="menuItem.moduleName == 'externalLink'"
      >
        <i
          class="fal fa-external-link"
          aria-hidden="true"
          style="font-size: 100%"
        />
      </sup>  
        
      <font-awesome-icon
        v-if="showAdmin"
        :style="{
          position: 'absolute',
          right: 'var(--el-menu-base-level-padding)'
        }"
        icon="fa-solid fa-pencil"
        class="ml-2"
        @click.prevent.stop="menuItemStore.edit"
      />
    </template>
  </el-menu-item>
</template>

<script setup>
import { computed } from 'vue'
import { assign } from 'lodash'
import { defineStore, storeToRefs } from 'pinia'
import { useMenuItemStore } from '@/stores/menuItem'
import { useUserConferenceStore } from '@/stores/userConference'
import router from '@/router'
import LocalizedValue from '@/components/LocalizedValue'

import {
  Document,
  Menu as IconMenu,
  Location,
  Setting
} from '@element-plus/icons-vue'


const props = defineProps({
  menuItemId: {
    type: String,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})

const { menuItemId } = props
const menuItemStore = useMenuItemStore(menuItemId)
const { 
  menuItem, 
  countBadge,
  route
} = storeToRefs(menuItemStore)

const { params } = route.value

const userConferenceStore = useUserConferenceStore()  
const { userConference, conferenceId, showAdmin } = storeToRefs(userConferenceStore)
const computedParams = computed(() => assign({}, params, menuItem.value.moduleConfig))

const closeMenuDrawer = () => userConferenceStore.$patch({ 
  showMenuDrawer: false
})

const navigateToRoute = () => {
  router.push({ name: menuItem.value.moduleName, params: computedParams.value, query: menuItem.value.moduleConfig })
  closeMenuDrawer()
}

</script>