<template>
  <el-dialog 
    v-if="editingStore"
    :model-value="editingStore"
    :style="{ zIndex: 1001 }"
    width="90%"
    :title="(editingStore.formProps && editingStore.formProps.title) ? $t(editingStore.formProps.title) : $t('common.edit')"
    @close="closeDialog"
  >
    <component
      :is="editingStore.form"
      :id="id"
    />

    <template 
      v-if="!editingStore.formProps"
      #footer
    >
      <span class="dialog-footer">
        <el-button 
          type="danger" 
          @click="showRemoveDialog"
        >
          {{ $t('common.delete') }}
        </el-button>
        <el-button 
          @click="closeDialog"
        >
          {{ $t('common.close') }}
        </el-button>
      </span>
    </template>

    <ConfirmRemoveDialog
      :showing="showingRemoveDialog"
      @cancel="closeRemoveDialog"
      @confirm="remove"
    />
  </el-dialog>
</template>

<script setup>
import { split } from 'lodash'
import { ref, toRef, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserConferenceStore } from '@/stores/userConference'
import ConfirmRemoveDialog from './FormModal/ConfirmRemoveDialog'

const userConferenceStore = useUserConferenceStore()  
const { userConference, editingStore } = storeToRefs(userConferenceStore)

const id = computed(() => split(editingStore.value.$id,'/')[1])

const showingRemoveDialog = ref(false)
const showRemoveDialog = () => showingRemoveDialog.value = true
const closeRemoveDialog = () => showingRemoveDialog.value = false

const closeDialog = () => userConferenceStore.$patch({ 
  editingStore: null
})

const remove = () => {
  editingStore.value.remove()
  closeRemoveDialog()
  closeDialog()
}

</script>
