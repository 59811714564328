const { state } = require('./options')


module.exports = [
  { name: 'id' },
  { name: 'name' },
  { name: 'theme', default: {} },
  { name: 'theme.colors', default: {} },
  { name: 'sessionsConfig', default: {
    showSearch: true,
  }},
  { name: 'sessionQuestionsConfig', default: {
    mustBeApproved: false,
    doNotStoreNames: false,
    hideNames: false
  }},
  { name: 'menu', default: []},
  { name: 'users', default: []},
  { name: 'speakers', default: []},
  { name: 'administrators', default: []},
  /* GENERAL */ 
  { name: 'availableLocales', default: ['en', 'cs', 'de']},
  { name: 'authMethods', default: ['email', 'google']},
  { name: 'defaultLocale', default: 'en'},
  { name: 'badges', default: []},
  { name: 'isOpen', default: false },
  { name: 'isVirtual', default: false },
  { name: 'visibilityInterval', default: 60 },
  // state 
  { name: 'state', options: state },
  { name: 'beforePage' },
  { name: 'afterPage' },
]
