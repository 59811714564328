const { type } = require('./options')

module.exports = [
  { name: 'id' },
  { name: 'conferenceId' },
  { name: 'roomId' },
  { name: 'parentId' },
  { name: 'speakers', default: [] },
  { name: 'badges', default: []},

  
  { name: 'name', localized: true }, 
  { name: 'abstract', localized: true },
  { name: 'code' },
  { name: 'type', default: 'standard', options: type },
  { name: 'start', timestamp: true },
  { name: 'end', timestamp: true }
]
