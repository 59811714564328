<template>
  <el-dropdown-item
    @click="navigateTo"
  >
    {{ $t(`${id}.title`) }}
  </el-dropdown-item>
</template>

<script setup>
import { defineStore, storeToRefs } from 'pinia'
import { toRefs, computed } from 'vue' 
import { useUserConferenceStore } from '@/stores/userConference'
import router from '@/router'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

const {
  id
} = props

const userConferenceStore = useUserConferenceStore()  
const { userConference, conferenceId, route } = storeToRefs(userConferenceStore)

const navigateTo = () => {
  router.push({ path: `/${conferenceId.value}/admin/${id}` })
}
</script>
