<template>
  <div>
    <ASelect
      :store="menuItemStore"
      field-name="moduleConfig.contentPageId"
      item-value="id"
      item-title="name"
      :items="contentPages"
      null-value
    />
  </div>
</template>

<script setup>
import { defineStore, storeToRefs } from 'pinia'
import { useContentPagesStore } from '@/stores/contentPages'
import { useMenuItemStore } from '@/stores/menuItem'
import ASelect from '@/components/ASelect'

const props = defineProps({
  menuItemId: {
    type: String,
    required: true
  }
})

const { menuItemId } = props
const menuItemStore = useMenuItemStore(menuItemId)
const { 
  menuItem
} = storeToRefs(menuItemStore)

const { contentPages } = storeToRefs(useContentPagesStore())  

</script>