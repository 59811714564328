<template>
  <div>
    <ASelect
      :store="menuItemStore"
      field-name="moduleConfig.roomId"
      item-value="id"
      item-title="name"
      :items="rooms"
      null-value
    />
  </div>
</template>

<script setup>
import { defineStore, storeToRefs } from 'pinia'
import { useRoomsStore } from '@/stores/rooms'
import { useMenuItemStore } from '@/stores/menuItem'
import ASelect from '@/components/ASelect'

const props = defineProps({
  menuItemId: {
    type: String,
    required: true
  }
})

const { menuItemId } = props
const menuItemStore = useMenuItemStore(menuItemId)
const { 
  menuItem
} = storeToRefs(menuItemStore)

const { rooms } = storeToRefs(useRoomsStore())  

</script>