import { createI18n } from 'vue-i18n'
import { keyBy, filter, mapValues } from 'lodash'
import { storeToRefs } from 'pinia'
import { unflatten } from 'flat'
import translationsData from './translationsData'
import { useLocalesStore } from './stores/locales'

// const keyedFrontTranslations = keyBy(filter(translationsData, { group: 'front' }), 'name')
// const unflattenLang = (lang) => unflatten(mapValues(keyedFrontTranslations, lang)) 

export default (messages, locale = 'en') => {
  const i18n = createI18n({
    globalInjection: true,
    legacy: false, // you must set `false`, to use Composition API
    locale,
    fallbackLocale: 'en',
    messages
  })

  return i18n
}

/*
export default (en) => {
console.log({ en })

const i18n = createI18n({
  globalInjection: true,
  legacy: false, // you must set `false`, to use Composition API
  locale: 'cs', // set locale
  fallbackLocale: 'en',
  messages: {
    en
  }
})

return i18n
}
*/