import { values, map } from 'lodash'
import { defineStore } from 'pinia'
import { useSpeakerStore } from '@//stores/speaker'
import fields from '@/../functions/common/fields/speaker'


export const useSpeakersStore = defineStore(`speakers`, {
  getters: {
  	fields: state => fields,
  	speakers: state => values(state.documents),
  	speakersWithSubtitles: state => map(state.speakers, speaker => ({
  		id: speaker.id,
  		name: `${speaker.degreeBefore} ${speaker.name} ${speaker.surname} ${speaker.degreeAfter} ${speaker.subtitle.en_US}` 
  	}))
  },
	actions: {
		useChildStore: (id, path) => useSpeakerStore(id, path)
	}
})