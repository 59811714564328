import { defineStore, acceptHMRUpdate } from 'pinia'
import { computed, reactive, markRaw } from 'vue'
import { filter } from 'lodash'
import { collection, getFirestore } from "firebase/firestore"
import { striptags } from '@/filters'
import { useSessionsStore } from './sessions'
import fields from '@/../functions/common/fields/room'
import form from '@/admin/Rooms/RoomForm' 

export const useRoomStore = (roomId, path = null) => defineStore(`rooms/${roomId}`, {
    state: () => ({
      document: reactive({}),
      form: markRaw(form),
      fullPath: `${path}/${roomId}`
    }),
    getters: {
      fields: state => fields,
      room: state => state.document
    },
    document: true
})()