import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

const login = function(firebase) {
  let uiConfig = {
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      },
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: 'CZ',
        recaptchaParameters: {
          type: 'image', // 'audio'
          size: 'invisible', // 'normal' or 'compact'
          badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
        }
      },
      {
        provider: 'anonymous',
        signInAnonymously() {
          console.log('loggging anonym')
        }
      }
    ],
    callbacks: {
      signInSuccessWithAuthResult () {
        window.location.href = '/'
      },
      uiShown() {
      }
      // signInAnonymously
    }
  }
  var ui = new firebaseui.auth.AuthUI(firebase.auth())
  ui.start('#app', uiConfig)
}

export default login
