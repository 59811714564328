import { createPinia } from 'pinia'
import { routerSync } from '@/stores/plugins/router'
import { sync } from '@/stores/plugins/firestore'

const store = 
	createPinia()
	.use(routerSync)
	.use(sync)

export default store
