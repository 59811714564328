import dayjs from 'dayjs'

export const dateyeartime = str => dayjs(str).format('DD.MM.YYYY H:mm')

export const datetime = str => (str).format('DD.MM. H:mm')

export const time = str => dayjs(str).format('H:mm')

export const date = str => dayjs(str).format('DD.MM.')

export const datename = str => dayjs(str).format('ddd')

export const striptags = str => {
  const div = document.createElement('div')
  div.innerHTML = str
  return div.innerText
}
