<template>
  <div>
    <ASelect
      :store="menuItemStore"
      field-name="moduleName"
      item-value="id"
      item-title="id"
      :items="availableModules"
      @update:modelValue="onModuleChange"
    />

    <LocalizedInput
      :store="menuItemStore"
      field-name="name"
    />

    <AIconSelect
      :store="menuItemStore"
      field-name="icon"
    />

    <ASelect
      :store="menuItemStore"
      field-name="parentId"
      item-value="id"
      :item-title="`name.${userConference.currentLocale}`"
      :items="allowedMenuItems"
      null-value
    />

    <template
      v-if="menuItem.moduleName && selectedModule.formConfig"
    >
      <component
        :is="selectedModule.formConfig"
        :menu-item-id="id"
      />
    </template>

    <ASelect
      :store="menuItemStore"
      field-name="badges"
      :item-value="null"
      :items="conference.badges"
      multiple  
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { find, reject } from 'lodash'
import { useMenuItemStore } from '@/stores/menuItem'
import { useMenuItemsStore } from '@/stores/menuItems'
import { useUserConferenceStore } from '@/stores/userConference'
import { useConferenceStore } from '@/stores/conference'
import router from '@/router'
import ASelect from '@/components/ASelect'
import AIconSelect from '@/components/AIconSelect'
import LocalizedInput from '@/components/LocalizedInput'

import sessions from './moduleConfig/sessions'
import externalLink from './moduleConfig/externalLink'
import contentPage from './moduleConfig/contentPage'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

const { id } = props
const menuItemStore = useMenuItemStore(id)
const { menuItem, route } = storeToRefs(menuItemStore)

const menuItemsStore = useMenuItemsStore()
const { menuItems } = storeToRefs(menuItemsStore)

const allowedMenuItems = computed(() => reject(menuItems.value, { id }))

const { params } = route.value

const { userConference } = storeToRefs(useUserConferenceStore())
const { conference } = storeToRefs(useConferenceStore())

const availableModules = [
  { id: 'sessions', formConfig: sessions, moduleConfig: { roomId: null } },
  { id: 'speakers' },
  { id: 'contentPage', formConfig: contentPage, moduleConfig: { contentPageId: null } },
  { id: 'externalLink', formConfig: externalLink, moduleConfig: { url: null } }
]

const selectedModule = computed(() => menuItem.value.moduleName ? find(availableModules, { id: menuItem.value.moduleName}) : null)

const onModuleChange = moduleChange => {
  const moduleConfig = selectedModule.moduleConfig || {}
  menuItemStore.$patch({ document: { moduleConfig }})
}
</script>