<template>
  <div>
    <AInput
      :store="roomStore"
      field-name="name"
    />
  </div>
</template>

<script setup>
import { defineStore, storeToRefs } from 'pinia'
import { toRefs, computed } from 'vue'
import { values } from 'lodash' 
import { useRoomStore } from '@/stores/room'
import { useRoomsStore } from '@/stores/rooms'
import LocalizedValue from '@/components/LocalizedValue'
import LocalizedInput from '@/components/LocalizedInput'
import AInput from '@/components/AInput'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

const { id } = props
const roomStore = useRoomStore(id)
const { room } = storeToRefs(roomStore)
</script>
