import { values, map } from 'lodash'
import { defineStore } from 'pinia'
import { useConferenceItemStore } from '@/stores/conferenceItem'
import fields from '@/../functions/common/fields/conference'


export const useConferencesStore = defineStore(`conferences`, {
  getters: {
  	fields: state => fields,
  	conferences: state => values(state.documents),
  	hasConferences: state => values(state.documents).length,
  	hasMultipleConferences: state => values(state.documents).length > 1
  },
	actions: {
		useChildStore: (id, path) => useConferenceItemStore(id, path)
	}
})