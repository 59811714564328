import { defineStore, acceptHMRUpdate } from 'pinia'
import { computed, reactive } from 'vue'
import { filter } from 'lodash'
import { collection, getFirestore } from "firebase/firestore"
import fields from '@/../functions/common/fields/conference'


export const useConferenceItemStore = (conferenceId = null, path = null) => defineStore(`conferences/${conferenceId}`, {
    state: () => ({ 
      document: reactive({}),
      fullPath: `${path}/${conferenceId}`
    }),
    getters: {
      conference: state => state.document,
      fields: state => fields
    },
    document: true
})()