<template>
  <div>
    <LocalizedInput
      :store="sessionStore"
      field-name="name"
    />

    <LocalizedInput
      :store="sessionStore"
      field-name="abstract"
      is-editor
    />

    <ASelect
      :store="sessionStore"
      field-name="parentId"
      item-value="id"
      :item-title="`name.${locale}`"
      :items="availableParentSessions"
      null-value
    />

    <ASelect
      :store="sessionStore"
      field-name="roomId"
      item-value="id"
      item-title="name"
      :items="rooms"
      required
    />

    <ASelect
      :store="sessionStore"
      field-name="speakers"
      item-value="id"
      item-title="name"
      :items="speakersWithSubtitles"
      multiple  
    />


    <ADateTimePicker
      :store="sessionStore"
      field-name="start"
      type="datetime"
      format="YYYY/MM/DD HH:mm"
    />

    <ADateTimePicker
      :store="sessionStore"
      field-name="end"
      type="datetime"
      format="YYYY/MM/DD HH:mm"
    />

    <ASelect
      :store="sessionStore"
      field-name="type"
      item-value="id"
      item-title="name"
    />
  </div>
</template>

<script setup>
import { defineStore, storeToRefs } from 'pinia'
import { toRefs, computed, watch } from 'vue'
import { values, filter, reject, last } from 'lodash' 
import dayjs from 'dayjs'

import { useSessionStore } from '@/stores/session'
import { useSessionsStore } from '@/stores/sessions'
import { useSpeakersStore } from '@/stores/speakers'
import { useRoomsStore } from '@/stores/rooms'
import { useUserConferenceStore } from '@/stores/userConference'
import { time } from '@/filters'
import LocalizedValue from '@/components/LocalizedValue'
import LocalizedInput from '@/components/LocalizedInput'
import AInput from '@/components/AInput'
import ASelect from '@/components/ASelect'
import ADateTimePicker from '@/components/ADateTimePicker'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

const { id } = props
const sessionStore = useSessionStore(id)
const { session } = storeToRefs(sessionStore)
const { sessions, rootSessions } = storeToRefs(useSessionsStore())
const { speakers, speakersWithSubtitles } = storeToRefs(useSpeakersStore())
const { rooms } = storeToRefs(useRoomsStore())  
const { userConference, locale } = storeToRefs(useUserConferenceStore())
const availableParentSessions = computed(() =>  reject(rootSessions.value, { id: session.value.id }))

const roomId = computed(() => session.value.roomId)
const parentId = computed(() => session.value.parentId)

watch(parentId, (parentId) => {
  if(parentId) {
    console.log('setting parent dependencies')
    const { document } = storeToRefs(useSessionStore(parentId))
    sessionStore.update({ 
      roomId: document.value.roomId
    })
  }
})

watch(roomId, (newVal, oldVal) => {
  if(oldVal !== newVal) {
    if(!session.value.start && !session.value.end) {
      if(session.value.parentId) {
        console.log('has a parent')
        const { subSessions, document } = storeToRefs(useSessionStore(session.value.parentId))
        const lastSession = last(filter(subSessions.value, session => session.id !== id))
        if(lastSession) {
          console.log('last in child sessions', lastSession)
          if(lastSession.end) {
            console.log('last in child sessions END', lastSession.end)
            sessionStore.update({ 
              start: lastSession.end,
              end: dayjs(lastSession.end).add(15, 'minute').toDate()
            })
          }
        } else {
          console.log('setting parent date', document.value.start)
          sessionStore.update({ 
            start: document.value.start,
            end: dayjs(document.value.start).add(15, 'minute').toDate()
          })
        }
      } else {
        // const { sessions } = storeToRefs(useSessionsStore())
        console.log('sessions', sessions.value)
        const siblingSessions = filter(sessions.value, session => session.roomId === newVal && session.id !== id)
        console.log({ siblingSessions })
        const lastSession = last(siblingSessions)
        console.log('last in room sessions', lastSession)
        if(lastSession && lastSession.end) {
          sessionStore.update({ 
            start: lastSession.end,
            end: dayjs(lastSession.end).add(1, 'hour').toDate()
          })
        }
      }
    }
  }
})
</script>
