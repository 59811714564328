import { CollectionReference, DocumentReference, onSnapshot, Query, Unsubscribe, setDoc } from "firebase/firestore"
import router from '@/router'
import { reactive } from 'vue'

export function routerSync({ store }) {
  store.router = router
  store.route = router.currentRoute
  if (process.env.NODE_ENV === 'development') {
    store._customProperties.add('route')
    store._customProperties.add('router')
  }
}