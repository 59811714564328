import { createPinia } from 'pinia'
import { routerSync } from '@/stores/plugins/router'
import { sync, update } from '@/stores/plugins/firestore'
import { edit } from '@/stores/plugins/edit'
import { remove } from '@/stores/plugins/remove'

const store = 
	createPinia()
	.use(routerSync)
	.use(sync)
	.use(update)
	.use(edit)
	.use(remove)

window.store = store

export default store
