import { getFirestore, onSnapshot, setDoc, doc, collection, collectionGroup, query, where } from "firebase/firestore"
import { computed, toRef, reactive } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { forEach, set, assign, debounce } from 'lodash'
import { useUserConferenceStore } from '@/stores/userConference'

export function edit({ store, options, pinia, app }) {
  store.edit = () => {
    const userConferenceStore = useUserConferenceStore()
    userConferenceStore.$patch({ editingStore: store })
  }
}

