const { get, set, forEach, isFunction, assign, mapValues, keyBy, isUndefined, concat, isArray } = require('lodash')
const { v4: uuid } = require('uuid')
const { getFirestore, doc, setDoc } = require("firebase/firestore")
const locales = require('./locales')
const firebaseApp = require('../init')


const getFieldValue = (field, data) => {
	const value = get(data, field.name)
	
	if(field.localized) {
		// console.log({ value })
		const localesObject = keyBy(locales, locale => locale)
		// console.log({ localesObject })
		const mapped = mapValues(localesObject, locale => value && value[locale] ? value[locale] : '')
		// console.log({ mapped })
		return mapped
	}

	if(!isUndefined(value)) {
		if(value && field.timestamp && value.toDate && isFunction(value.toDate)) {
			return value.toDate()
		}
		return value
	}

	return isUndefined(field.default) ? '' : field.default
}

const assignDocument = ({
  fields,
  data
}) => {
	let newDocument = {}
	const newFields = isArray(fields) ? concat(fields, [
    { name: 'createdAt', timestamp: true },
    { name: 'updatedAt', timestamp: true }
  ]) : null

	forEach(newFields, field => {
	  const value = getFieldValue(field, data)
	  // console.log(field.name, value)
	  set(newDocument, field.name, value)
	})
	return newDocument
} 

const createDocument = ({
	path, 
	fields,
	params, 
	options
}) => {
	const id = uuid()
	const data = assign({ id }, params)
	
	let newDocumentData = assignDocument({
		fields,
		data
	})
	
	console.log(path, newDocumentData.id, newDocumentData)
	setDoc(doc(getFirestore(firebaseApp), path, newDocumentData.id), newDocumentData, options)
	return newDocumentData
}

module.exports = {
	getFieldValue,
	createDocument,
	assignDocument
}