import { createRouter, createWebHistory } from 'vue-router'
import { settingsRoutes } from '@/admin/Conference/settingsItems'

const routes = [
    { 
      path: '',
      name: 'selectConference', 
      component:() => import('@/views/Conference/SelectConference') 
    },
    { 
      path: '/default',
      redirect: '/'
    },
    { 
      name: 'createConferenceId',
      path: '/createConferenceId', 
      component:() => import('@/views/Conference/CreateConferenceId') 
    },

    {
      name: 'home',
      path: '/:conferenceId',
      component: () => import('@/views/Conference/ConferenceHome')
    },
    { 
      name: 'speakers',
      path: '/:conferenceId/speakers', 
      component: () => import('@/views/Speakers/SpeakersList')
    },
    { 
      path: '/:conferenceId/speakers/:speakerId', 
      component: () => import('@/views/Speakers/SpeakerDetail'),
      props: true
    },
    { 
      name: 'contentPage',
      path: '/:conferenceId/contentPages/:contentPageId', 
      component: () => import('@/views/ContentPage'),
      props: true
    },
    { 
      name: 'sessions',
      path: '/:conferenceId/sessions', 
      component: () => import('@/views/Sessions/SessionsList')
    },
    { 
      name: 'sessionQuestions',
      path: '/:conferenceId/sessionQuestions/:sessionId', 
      component: () => import('@/views/Sessions/SessionQuestions/SessionQuestionsList'),
      props: true
    },
    
    /* ADMIN */
    { 
      path: '/:conferenceId/settings', 
      component: () => import('@/admin/Conference/Index'),
      name: 'settings',
      meta: { hideSidebar: true }
    },
    { 
      name: 'speaker',
      path: '/:conferenceId/admin/speaker', 
      component: () => import('@/admin/Speaker/Questions'),
      meta: { hideSidebar: true }
    },
    { 
      path: '/:conferenceId/admin/contentPages', 
      component: () => import('@/admin/ContentPages/ContentPagesList'),
      meta: { hideSidebar: true }
    },
    { 
      path: '/:conferenceId/admin/conferenceUsers', 
      component: () => import('@/admin/ConferenceUsers/ConferenceUsersList'),
      meta: { hideSidebar: true }
    },
    { 
      name: 'createConferenceUser',
      path: '/:conferenceId/admin/conferenceUsers/create', 
      component: () => import('@/admin/ConferenceUsers/CreateConferenceUser'),
      meta: { hideSidebar: true }
    },
    { 
      path: '/:conferenceId/admin/rooms', 
      component: () => import('@/admin/Rooms/RoomsList'),
      meta: { hideSidebar: true }
    },
    { 
      path: '/:conferenceId/admin/', 
      component: () => import('@/admin/Conference/Index'),
      meta: { hideSidebar: true },
      children: [
        { path: 'general', component: () => import('@/admin/Conference/General') },
        { path: 'modules', component: () => import('@/admin/Conference/Modules') },
        { path: 'theme', component: () => import('@/admin/Conference/Theme') },
        { path: 'locales', component: () => import('@/admin/Conference/Locales') }
      ]
    },
    /*{ 
      path: '/:conferenceId/admin/', 
      component: () => import('@/admin/Conference/Colors')
    },
    { 
      path: '/:conferenceId/admin/images', 
      component: () => import('@/admin/Conference/Images')
    },*/

    
    { 
      path: '/:pathMatch(.*)*', 
      redirect: { name: 'selectConference'}
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router