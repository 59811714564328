module.exports = [
  { name: 'id', foreign: 'unique_id' },
  { name: 'conferenceId' },
  { name: 'uid' },
  { name: 'badges', default: [] },
  { name: 'firstname' },
  { name: 'surname', foreign: 'lastname' },
  { name: 'email' },
  { name: 'phone' }, 
  { name: 'language', foreign: 'lang' }, 
  { name: 'country', foreign: 'zeme' }, 
  { name: 'company', foreign: 'firma' }, 
  { name: 'position' }, 
  { name: 'general_field_1' }, 
  { name: 'general_field_2' }, 
  { name: 'general_field_3' }, 
]
