<template>
  <div
    :style="{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: '2em',
      paddingRight: '2em'
    }"
  >
    <div
      :style="{
        display: 'flex',
        alignItems: 'center'
      }"
    >
      <el-icon
        size="20"
        class="hidden-md-and-up"
        :style="{
          marginRight: '1em',
          cursor: 'pointer'
        }"
        @click="openMenuDrawer"
      >
        <Menu />
      </el-icon>

      <img  
        :src="get(fileUrls, 'virtualLogo.png')"
        @click="goHome"
      >
    </div>

    <el-space wrap>
      <AdminControls
        v-if="showAdmin"
      />
      <el-icon
        v-if="showAdmin || hasMultipleConferences"
        :style="{
          cursor: 'pointer'
        }"
        @click="selectConference"
      >
        <Switch />
      </el-icon>

      <el-icon
        :style="{
          cursor: 'pointer'
        }"
        size="20"
        @click="openSettingsDialog"
      >
        <Setting />
      </el-icon>

      <el-icon
        v-if="showAdmin || isSpeaker"
        size="20"
        :style="{
          cursor: 'pointer'
        }"
        @click="goToSpeaker"
      >
        <ChatLineSquare />
      </el-icon>
      
      <AdminList 
        v-if="isAdmin"
      />
    </el-space>
  </div>
</template>

<script setup>
import { get } from 'lodash'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import router from '@/router'
import AdminList from '@/admin/AdminList'
import AdminControls from '@/admin/AdminControls'

import {
  Switch
} from '@element-plus/icons-vue'


const activeIndex = ref('1')
const handleSelect = (key, keyPath) => {
  console.log(key, keyPath)
}

import { useUserStore } from '@/stores/user'
import { useUserConferenceStore } from '@/stores/userConference'
import { useConferencesStore } from '@/stores/conferences'
import { useConferenceStore } from '@/stores/conference'

const userStore = useUserStore()
const { isSpeaker, isAdmin } = storeToRefs(userStore)

const userConferenceStore = useUserConferenceStore()
const { userConference, route, showAdmin } = storeToRefs(userConferenceStore)

const conferencesStore = useConferencesStore()
const { hasMultipleConferences } = storeToRefs(conferencesStore)
const { params } = route.value

const conferenceStore = useConferenceStore()
const { fileUrls } = storeToRefs(conferenceStore)

const goToAdmin = () => router.push({ name: 'adminList', params })

const goToSpeaker = () => router.push({ name: 'speaker', params })

const goHome = () => router.push({ name: 'home' })

const selectConference = () => router.push({ name: 'selectConference' })

const openSettingsDialog = () => userConferenceStore.$patch({ 
  showSettingsDialog: true
})

const openMenuDrawer = () => userConferenceStore.$patch({ 
  showMenuDrawer: true
})

</script>
