<template>
  <el-form-item 
    :label="hideLabel ? '' : $t(label)"
  >
    <el-input
      :model-value="typing"
      v-bind="$attrs"
      @change="update"
      @input="updateTyping"
    />
  </el-form-item>
</template>

<script setup>
import { get, set, clone } from 'lodash'
import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useField } from '@/composables/field'

const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  fieldName: {
    type: String,
    required: true
  },
  customLabel: {
    type: String,
    default: null
  },
  hideLabel: {
    type: Boolean,
    default: false
  },
  debounce: {
    type: Number,
    default: 0
  }
})

const {
  store,
  fieldName,
  customLabel,
  debounce
} = props

const {
  value,
  label,
  update,
  updateDebounced
} = useField({
  store,
  fieldName,
  customLabel,
  debounceMs: debounce
})

let typing = ref(clone(value.value))

const updateTyping = newValue => {
  typing.value = newValue
  if(debounce) {
    updateDebounced(newValue, debounce)
  }
}

</script>
