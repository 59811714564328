import { get, set, find, debounce, split, isString, assign } from 'lodash'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { getFirestore, setDoc, doc } from "firebase/firestore"

export function updateDB({
  fullPath,
  data
}) {
  const newData = assign({
    updatedAt: new Date()
  }, data)
  console.log(`updating ${fullPath}`, newData)
  setDoc(doc(getFirestore(), fullPath), newData, { merge: true })
}


export function useField({
  store,
  fieldName,
  customLabel = null,
  debounceMs = 0
}) {
  const debouncedUpdate = debounce(updateDB, debounceMs)
  
  const moduleName = computed(() => split(store.$id, '/')[0])

  const value = computed(() => get(store, `document.${fieldName}`))
  // const update = value => store.$patch(set({}, `document.${fieldName}`, value))
  
  const updateDebounced = value => debouncedUpdate({
    fullPath: store.fullPath,
    data: set({}, `${fieldName}`, value)
  })

  const update = value => updateDB({
    fullPath: store.fullPath,
    data: set({}, `${fieldName}`, value)
  })

  const { fields } = storeToRefs(store)

  const fieldData = find(fields.value, { name: fieldName })
  // if(fieldData && fieldData.default && !isObject(fieldData.default)) {
    const fieldOptions = fieldData ? fieldData.options : undefined
    const standardLabel = `${moduleName.value}.fields.${fieldName}`
    const label = customLabel || standardLabel
  // console.log(fieldName, fieldData)

  return {
    value,
    update,
    updateDebounced,
    fieldOptions,
    label,
    standardLabel
  }
}

