import { values } from 'lodash'
import { defineStore } from 'pinia'
import { useContentPageStore } from '@/stores/contentPage'
import fields from '@/../functions/common/fields/contentPage'


export const useContentPagesStore = defineStore(`contentPages`, {
  getters: {
  	fields: state => fields,
  	contentPages: state => values(state.documents)
  },
	actions: {
		useChildStore: (id, path) => useContentPageStore(id, path)
	}
})