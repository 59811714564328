<template>
  <div 
    :style="{
      width: '100%'
    }"
  >
    <el-menu
      :style="{
        borderRight: 'none'
      }"
    >
      <MenuItem
        v-for="menuItem in menuItems"
        :key="menuItem.id"
        :index="menuItem.id"
        :menu-item-id="menuItem.id"
      />
    </el-menu>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import AddButton from '@/components/AddButton'
import LocalizedValue from '@/components/LocalizedValue'
import MenuItem from './MenuItems/MenuItem'
import { useMenuItemsStore } from '@/stores/menuItems'

const menuItemsStore = useMenuItemsStore()
const { menuItems } = storeToRefs(menuItemsStore)

</script>
