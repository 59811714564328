<template>
  <div>  
    <span 
      class="el-form-item__label"
    >
      {{ $t(standardLabel) }}
    </span>
    
    <el-tabs
      v-model="tab"
    >
      <el-tab-pane 
        v-for="locale in availableLocales" 
        :key="locale"
        :label="$t(`common.locales.${locale}`)"
        :name="locale"
      >
        <template #label>
          <span class="custom-tabs-label">
            <span :class="`fi fi-${getFlagLocale(locale)}`" />
            &nbsp;
            <span>{{ $t(`common.locales.${locale}`) }}</span>
            &nbsp;
            <el-icon>
              <Check 
                v-if="hasText(locale)"
                color="var(--el-color-success)"
              />
            </el-icon>
          </span>
        </template>

        <component
          :is="isEditor ? AEditor : AInput"
          :key="locale"
          :store="store"
          :field-name="`${fieldName}.${locale}`"
          :custom-label="standardLabel"
          hide-label
          :locale="locale"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import { computed, ref, reactive, toRef } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { get, set } from 'lodash'
import { useConferenceStore } from '@/stores/conference'
import { useUserConferenceStore } from '@/stores/userConference'
import { useField } from '@/composables/field'
import { getFlagLocale } from '@/functions'

import AInput from './AInput'
import AEditor from './AEditor'

const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  fieldName: {
    type: String,
    required: true
  },
  isEditor: {
    type: Boolean,
    required: false
  }
})

const {
  store,
  fieldName
} = props

const conferenceStore = useConferenceStore()
const { conference } = storeToRefs(conferenceStore)
const { availableLocales } = conference.value

const userConferenceStore = useUserConferenceStore()
const { currentLocale } = storeToRefs(userConferenceStore)

let tab = ref(availableLocales[0])

const {
  value,
  standardLabel
} = useField({
  store,
  fieldName
})

const storeData = storeToRefs(store)
const { document } = storeData

const hasText = locale => get(document.value, `${fieldName}.${locale}`)

</script>
