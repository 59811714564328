<template>
  <div
    v-if="rooms.length > 1"
    class="text-center"
  >
    <v-radio-group
      :value="roomId"
    >
      <template #label>
        <h6 class="text-h6">
          {{ $t('settings.rooms.title') }}
        </h6>
      </template>

      <v-radio
        key="all"
        value="all"
        :label="$t('settings.rooms.all_rooms')"
        @click="selectNone"
      />

      <v-radio
        v-for="room in rooms"
        :key="room.id"  
        :value="room.id"
        :label="room.name"
        @click="() => selectRoom(room.id)"
      />
    </v-radio-group>

    <v-divider />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useRoomsStore } from '@/stores/rooms'
import { useUserConferenceStore } from '@/stores/userConference'

import router from '@/router'

const roomsStore = useRoomsStore()
const {
  rooms,
  currentRoom,
  route
} = storeToRefs(roomsStore)

const { conferenceId } = route.value.params 
const roomId = currentRoom ? Number(currentRoom.id) : 'all'

const userConferenceStore = useUserConferenceStore()

const selectNone = () => {
  userConferenceStore.update({ currentRoomId: null})
  // router.push(`/conferences/${conferenceId}`)
}

const selectRoom = (roomId) => {
  userConferenceStore.update({ currentRoomId: roomId })
  // router.push(`/conferences/${conferenceId}/rooms/${roomId}`)
}

</script>
