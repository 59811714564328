import { defineStore, acceptHMRUpdate } from 'pinia'
import { computed, reactive, markRaw } from 'vue'
import { filter, includes } from 'lodash'
import { collection, getFirestore } from "firebase/firestore"
import fields from '@/../functions/common/fields/conferenceUser'
import form from '@/admin/ConferenceUsers/ConferenceUserForm' 

export const useConferenceUserStore = (conferenceUserId, path = null) => defineStore(`conferenceUsers/${conferenceUserId}`, {
    state: () => ({
      document: reactive({}),
      form: markRaw(form),
      fullPath: `${path}/${conferenceUserId}`
    }),
    getters: {
      fields: state => fields,
      conferenceUser: state => state.document,
      badges: state => state.document ? state.document.badges : []
    },
    document: true
  }
)()