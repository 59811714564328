// import firebase from 'firebase'
import { firebaseConfig } from "@/firebase"
import { initializeApp } from "firebase/app"
import { getFunctions, httpsCallable, region } from "firebase/functions"
import { get, kebabCase } from 'lodash'

import elCs from 'element-plus/dist/locale/cs.mjs'
import elEn from 'element-plus/dist/locale/en.mjs'
import elDe from 'element-plus/dist/locale/de.mjs'


const regionName = 'europe-west1'

async function callable (functionName, data) {
  const fn = httpsCallable(getFunctions(initializeApp(firebaseConfig), regionName), `callables-${functionName}`)
  // const searchConferenceUsers = firebase.functions().region(region).httpsCallable('callables-searchConferenceUsers')
  try {
    const result = await fn(data)
    return result.data
  } catch (e) {
    console.log(e)
  }
}

function getFlagLocale (locale) {
  const localesMap = {
    cs: 'cz',
    en: 'us'
  }

  return get(localesMap, locale) || locale
}

function getElementLocale (locale) {
  const localesMap = {
    cs: elCs,
    en: elEn,
    de: elDe
  }

  return get(localesMap, locale) || locale
}

function urlify (s) {
    var r = s.toLowerCase();
    r = r.replace(new RegExp(/\s/g),"");
    r = r.replace(new RegExp(/[àáâãäå]/g),"a");
    r = r.replace(new RegExp(/çč/g),"c");
    r = r.replace(new RegExp(/ď/g),"d");
    r = r.replace(new RegExp(/[ěèéêë]/g),"e");
    r = r.replace(new RegExp(/[ìíîï]/g),"i");
    r = r.replace(new RegExp(/ñň/g),"n");                
    r = r.replace(new RegExp(/[òóôõö]/g),"o");
    r = r.replace(new RegExp(/ř/g),"r");
    r = r.replace(new RegExp(/š/g),"s");
    r = r.replace(new RegExp(/ť/g),"t");
    r = r.replace(new RegExp(/[ùúûü]/g),"u");
    r = r.replace(new RegExp(/[ýÿ]/g),"y");
    r = r.replace(new RegExp(/[ž]/g),"z");
    r = r.replace(new RegExp(/\W/g),"");
    return kebabCase(r);
}

export {
  callable,
  getFlagLocale,
  getElementLocale,
  urlify
}
