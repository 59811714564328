<template>
  <el-dialog
    :model-value="showSettingsDialog"
    @close="closeDialog"
  >
    <h1>{{ $t('userConferences.form.title') }}</h1>
    <h2>UID: {{ auth.uid }}</h2>
    <h2>Email: {{ auth.email }}</h2>
    <h2>Name: {{ auth.displayName }}</h2>

    <RoomSelect />
        
    <LocaleSelect />

    <el-button
      type="primary"
      @click="changeCookieSettings"
    >
      <el-icon>
        <Cookie />
      </el-icon>
      {{ $t('userConferences.form.cookies') }}
    </el-button>

    <el-button
      type="primary"
      @click="logout"
    >
      <el-icon>
        <Logout />
      </el-icon>
      {{ $t('userConferences.form.logout') }}
    </el-button>
  </el-dialog>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { toRef } from 'vue'
import { signOut } from 'firebase/auth'
import { firebaseAuth } from '@/firebase'

import { useUserConferenceStore } from '@/stores/userConference'
import { useUserStore } from '@/stores/user'
import RoomSelect from './RoomSelect'
import LocaleSelect from './LocaleSelect'
import AInput from '@/components/AInput'

const userConferenceStore = useUserConferenceStore()  
const { userConference, showSettingsDialog } = storeToRefs(userConferenceStore)

const userStore = useUserStore()
const { auth, user, isSpeaker, isAdmin, isSuperAdmin } = storeToRefs(userStore)
const closeDialog = () => userConferenceStore.$patch({
  showSettingsDialog: false
})

const changeCookieSettings = () => {
  cc.showSettings()
}

const logout = () => signOut(firebaseAuth)
</script>

<style>
  .el-dialog {
      --el-dialog-width: 90%;
      max-width: 691px;
    }
</style>