<template />

<script setup>
import { cloneDeep } from 'lodash'
import { computed, watch, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useConferenceStore } from '@/stores/conference'
import { useLoginStore } from '@/stores/login'
const conferenceStore = useConferenceStore()
const { conference, loginConferenceId } = storeToRefs(conferenceStore)
import { firebaseAuth } from "@/firebase"
import { sendSignInLinkToEmail } from "firebase/auth"
const loginStore = useLoginStore()
const { status } = storeToRefs(loginStore)
import { getAuth, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";


const url = computed(() => `https://ff.easyevent.cz/${loginConferenceId.value}`)

const provider = new GoogleAuthProvider();

// useDeviceLanguage()

signInWithRedirect(getAuth(), provider)


</script>