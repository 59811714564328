import { defineStore, storeToRefs } from 'pinia'
import { collection, getFirestore } from "firebase/firestore"
import { groupBy, filter, map, values, orderBy, includes, keys, get, some, remove } from 'lodash'
import dayjs from 'dayjs'
import { useSessionStore, searchedSessionFilterFn } from './session'
import { useSpeakersStore } from './speakers'
import { useUserStore } from './user'
import { useUserConferenceStore } from './userConference'
import fields from '@/../functions/common/fields/session'

export const groupSessionsByDate = (sessions) => orderBy(map(groupBy(sessions, document => dayjs(document.start).format('YYYY-MM-DD')), (sessions, date) => {
	return {
		sessions,
		date
	}
}), 'date')

export const useSessionsStore = defineStore(`sessions`, {
  getters: {
  	fields: state => fields,
    sessions: state => {
    	let allSessions = orderBy(values(state.documents), ['start'])
  		const { showFavorites, roomId } = state.route.query
  		const userConferenceStore = storeToRefs(useUserConferenceStore()) 
  		if(showFavorites) {
  			const { favoriteSessions } = userConferenceStore
  			return filter(allSessions, session => includes(favoriteSessions, session.id))
  		} else {
	  		const { currentRoomId, userConference, locale } = userConferenceStore
	  		const { searchSessions } = userConference.value
	  		if(searchSessions) {
					allSessions = filter(allSessions, session => {
					if(searchedSessionFilterFn(session)) {
						return true
					}
					const { subSessions } = storeToRefs(useSessionStore(session.id))
					return some(subSessions.value, subSession => searchedSessionFilterFn(subSession))
					})
  			}
	  		if(roomId) {
	  			return filter(allSessions, { roomId })
	  		}
	  		if(currentRoomId && currentRoomId.value) {
	  			return filter(allSessions, { roomId: currentRoomId.value })
	  		}
	  		return allSessions
	  	}
    },
  	rootSessions: state => {
  		const userConferenceStore = storeToRefs(useUserConferenceStore()) 
  		const { userConference } = userConferenceStore
  		const { searchSessions } = userConference.value
  		return filter(state.sessions, session => {
  		if(!session.parentId) {
	  		if(searchSessions) {
					const found = searchedSessionFilterFn(session)
					if(found) {
						return true
					}
					const { subSessions } = storeToRefs(useSessionStore(session.id))
					return some(subSessions.value, subSession => searchedSessionFilterFn(subSession))
				} else {
					return true
				}
			}
			return false
  	})
  	},
  	roomSessions: state => {
  		const { roomId } = state.route.params
  		// console.log({ roomId })
  		return filter(state.rootSessions, { roomId })
  	},
  	sessionsByDates: state => groupSessionsByDate(state.sessions),
  	sessionsForQuestionsAnswering: state => filter(state.documents, session => { 
  		 const { isAdmin, isSpeaker, uid }  = storeToRefs(useUserStore())
  		 if(isAdmin.value) {
  		 		return state.documents
  		 }
  		 if(isSpeaker.value) {
  		 		return filter(state.documents, session => includes(session.speakers, uid))
  		 }
  		 return []
  	}),
    roomIds: state => remove(keys(groupBy(state.sessionsForQuestionsAnswering, 'roomId')), ''),
  	getLabel: state => (id, fieldName = 'name') => {
  		const doc = get(state.documents, id)
  		if(!doc) {
  			console.error(`${id} not found in sessions`)
  			return null
  		}

			const field = find(state.fields, { fieldName }) 
  		if(!doc) {
  			console.error(`${id} not found in sessions`)
  			return null	
  		}

  		if(field.localized) {
  			const userConferenceStore = useUserConferenceStore() 
  			const { locale } = storeToRefs(userConferenceStore)
  			return get(doc, `${fieldName}.${locale.value}`)
  		}
  		return get(doc, fieldName)
  	}
	},
	actions: {
		useChildStore: (id, path) => useSessionStore(id, path)
	}
})
