<template>
  <el-form-item 
    :label="hideLabel ? '' : $t(label)"
  >
    <el-date-picker
      v-model="typing"
      v-bind="$attrs" 
      @change="update"
    />
  </el-form-item>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { get, set, clone } from 'lodash'
// import Datepicker from '@vuepic/vue-datepicker'
// import '@vuepic/vue-datepicker/dist/main.css'
import { useField } from '@/composables/field'

const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  fieldName: {
    type: String,
    required: true
  },
  customLabel: {
    type: String,
    default: null
  }
})

const {
  store,
  fieldName,
  customLabel
} = props

const {
  value,
  label,
  update
} = useField({
  store,
  fieldName,
  customLabel
})

// let typing = ref(clone(value.value))
let typing = ref(null)

watch(value, (newVal) => {
  typing.value = clone(newVal)
})


</script>

