module.exports = [
  { name: 'id' },

  { name: 'currentRoomId' },
  { name: 'currentLocale', default: 'en' },
  // sessions
  { name: 'starredSessions', default: {} },
  { name: 'favoriteSessions', default: [] },
  { name: 'searchSessions', default: '' },

  //admin sessionQuestions
  { name: 'adminSessionQuestions', 
    default: {
      roomId: null,
      orderBy: 'createdAt'
    } 
  },
  { name: 'adminConferenceUsers', 
    default: {
      fields: ['firstname', 'surname', 'email', 'phone', 'company']
    },
  },
]
