const firebase = require('firebase/compat/app')

// const { firebaseConfig } = require('./config')

const firebaseConfig = {
  apiKey: "AIzaSyC4Cg_jtmxbBMxCf94HpooRUajirWcJqUk",
  authDomain: "easyeventcz.firebaseapp.com",
  projectId: "easyeventcz",
  storageBucket: "easyeventcz.appspot.com",
  messagingSenderId: "296682748322",
  appId: "1:296682748322:web:f89a9278f3b168bfc9dafb"
}


const fbApp = firebase.default.initializeApp(firebaseConfig)

module.exports = fbApp