<template>
  <div>
    <el-drawer
      v-if="showMenuDrawer"
      model-value="showMenuDrawer"
      direction="ltr"
      size="400"
      @close="closeMenuDrawer"
    >
      <el-menu
        :style="{
          borderRight: 'none'
        }"
      >
        <MenuItem
          v-for="menuItem in menuItems"
          :key="menuItem.id"
          :index="menuItem.id"
          :menu-item-id="menuItem.id"
        />
      </el-menu>
    </el-drawer>

    <AddButton
      :store="menuItemsStore"
    />
  </div>
</template>

<script setup>
import { split } from 'lodash'
import { ref, toRef, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserConferenceStore } from '@/stores/userConference'
import ConfirmRemoveDialog from './FormModal/ConfirmRemoveDialog'
import MenuItem from './MenuItems/MenuItem'
import { useMenuItemsStore } from '@/stores/menuItems'

const menuItemsStore = useMenuItemsStore()
const { menuItems } = storeToRefs(menuItemsStore)

const userConferenceStore = useUserConferenceStore()  
const { userConference, showMenuDrawer, showAdmin } = storeToRefs(userConferenceStore)

const closeMenuDrawer = () => userConferenceStore.$patch({ 
  showMenuDrawer: false
})

</script>
