<template>
  <div>
    <div :style="{ float: 'right' }">
      <el-button
        :style="{
          marginBottom: '1em'
        }"
        @click="generateSignInLink"
      >
        {{ $t('conferenceUsers.generateSignInLink') }}
      </el-button>
    </div>
    <div :style="{ clear: 'both' }" />
    <!-- <AInput
      :store="conferenceUserStore"
      field-name="name"
      disabled
    /> -->

    <AInput
      :store="conferenceUserStore"
      field-name="firstname"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="surname"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="email"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="phone"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="language"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="country"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="company"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="position"
    />

    <ASelect
      :store="conferenceUserStore"
      field-name="badges"
      :items="conference.badges"
      multiple
      :item-value="null"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="general_field_1"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="general_field_2"
    />

    <AInput
      :store="conferenceUserStore"
      field-name="general_field_3"
    />
  </div>
</template>

<script setup>
import { defineStore, storeToRefs } from 'pinia'
import { toRefs, computed } from 'vue'
import { values } from 'lodash' 
import { useConferenceUserStore } from '@/stores/conferenceUser'
import { useConferenceUsersStore } from '@/stores/conferenceUsers'
import { useConferenceStore } from '@/stores/conference'
import LocalizedValue from '@/components/LocalizedValue'
import LocalizedInput from '@/components/LocalizedInput'
import AInput from '@/components/AInput'
import ASelect from '@/components/ASelect'
import { callable } from '@/functions'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'


const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

const { id } = props
const conferenceUserStore = useConferenceUserStore(id)
const { conferenceUser } = storeToRefs(conferenceUserStore)
const conferenceStore = useConferenceStore()
const { conference } = storeToRefs(conferenceStore)

const generateSignInLink = async() => {
  const data = {
    conferenceId: conference.value.id,
    id,
    email: conferenceUser.value.email
  }
  console.log({ data })
  const link = await callable('generateSignInLink', data)
  ElMessageBox.alert(
    link,
    {
      dangerouslyUseHTMLString: true
    }
  )
}
</script>
