import { defineStore, acceptHMRUpdate } from 'pinia'
import { computed, reactive, markRaw } from 'vue'
import { filter, includes } from 'lodash'
import { collection, getFirestore } from "firebase/firestore"
// import fields from '@/../functions/common/fields/locale'
// import form from '@/admin/Locales/LocaleForm' 

export const useLocaleStore = (localeId, path = null) => defineStore(`locales/${localeId}`, {
    state: () => ({
      document: reactive({}),
      // form: markRaw(form),
      fullPath: `${path}/${localeId}`
    }),
    getters: {
      // fields: state => fields,
      locale: state => state.document
    },
    document: true
  }
)()