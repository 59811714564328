import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { forEach } from 'lodash'
import "/node_modules/flag-icons/css/flag-icons.min.css"
import { fas } from '@fortawesome/free-solid-svg-icons'

/*import { 
  faUserSecret,
  faPencil,
  faCheck,
  faDisplay,
  faCompass,
  faUserTie,
  faSliders,
  faThumbtack,
  faThumbsUp,
  faTrash,
  faQuestion,
  faBoxArchive
} from '@fortawesome/free-solid-svg-icons'*/


// console.log({ faIcons })

export default ({ app }) => {
  
  /*library.add(
    faUserSecret,
    faPencil,
    faCheck,
    faDisplay,
    faCompass,
    faUserTie,
    faSliders,
    faThumbtack,
    faThumbsUp,
    faTrash,
    faQuestion,
    faBoxArchive
  )*/

  // forEach(faIcons, faIcon => library.add(faIcon))
  library.add(fas)

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }

    app.component('FontAwesomeIcon', FontAwesomeIcon)

}

export {
  fas
}
