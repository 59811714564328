<template>
  <div>
    <el-upload
      :style="{ marginBottom: '2em'}"
      v-bind="$attrs"
      :model-value="fileToUpload"
      drag
      :auto-upload="false"
      :on-change="upload"
    >
      <template #tip>
        {{ label }}
      </template>
      <img 
        v-if="url"
        :key="newKey"
        :src="url"
      >
      <span 
        v-else
      >
        <el-icon class="avatar-uploader-icon"><Plus /></el-icon>
        {{ $t('components.aUpload.dragImageHere') }}
      </span>
    </el-upload>
  </div>
</template>

<script setup>
import { get, set } from 'lodash'
import { computed } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useFile } from '@/composables/file'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { UploadFile } from 'element-plus'

const props = defineProps({
  store: {
    type: Object,
    required: true
  },
  path: {
    type: String,
    required: true
  },
  fileName: {
    type: String,
    default: props => props.store.$id
  },
  suffix: {
    type: String,
    default: ''
  }, 
  size: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  }
})

const {
  store,
  path,
  fileName,
  suffix,
  size
} = props

const fileToUpload = ref({})
const newKey = computed(() => {
  return key.value
})

const {
  file,
  url,
  upload,
  key
  // progress
} = useFile({
  store,
  path,
  fileName,
  suffix,
  size
})

</script>
