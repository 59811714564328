<template>
  <div
    class="text-center"
  >
    <h6 class="text-h6">
      {{ $t('common.locale') }}
    </h6>

    <el-select 
      :model-value="userLocale"
      :placeholder="$t('components.aSelect.placeholder')"
      value-key="name"
    >
      <template #prefix>
        <span :class="`fi fi-${getFlagLocale(userConference.currentLocale)}`" />
      </template>

      <el-option
        v-for="locale in availableLocales"
        :key="locale.name"
        :value="locale.name"
        :label="locale.label"
        @click="selectLocale(locale.name)"
      >
        <span :class="`fi fi-${getFlagLocale(locale.name)}`" />
        {{ $t(`common.locales.${locale.name}`) }}
      </el-option>
    </el-select>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { map } from 'lodash'
import { useI18n } from 'vue-i18n'
import { useUserConferenceStore } from '@/stores/userConference'
import { useUserStore } from '@/stores/user'
import { useConferenceStore } from '@/stores/conference'
import { getFlagLocale } from '@/functions'

const userConferenceStore = useUserConferenceStore()
const { userConference } = storeToRefs(userConferenceStore)

const userStore = useUserStore()
const { userLocale } = storeToRefs(userStore)

const { conference } = storeToRefs(useConferenceStore())

const { t } = useI18n()
const i18n = useI18n()

const availableLocales = map(conference.value.availableLocales, locale => ({
  name: locale,
  label: t(`common.locales.${locale}`)
}))

const selectLocale = (currentLocale) => {
  userConferenceStore.update({ currentLocale })
  i18n.locale.value = currentLocale
}


</script>
