<template>
  <div
    :style="{
      position: 'relative',
      paddingTop: '56.25%',
      marginBottom: '2em'
    }"
  >
    <iframe 
      :src="cdnUrl"
      frameborder="0"
      allowfullscreen
      allow="autoplay; encrypted-media"
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
    />
  </div>
</template>

<script setup>
const cdnUrl = 'https://www.youtube.com/embed/21X5lGlDOfg'
</script>

