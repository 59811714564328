import { defineStore, acceptHMRUpdate } from 'pinia'
import { computed, reactive, markRaw } from 'vue'
import { filter, includes } from 'lodash'
import { collection, getFirestore } from "firebase/firestore"
import { striptags } from '@/filters'
import { useSessionsStore } from './sessions'
import { useUserConferenceStore } from './userConference'
import fields from '@/../functions/common/fields/speaker'
import form from '@/views/Speakers/SpeakerForm' 
import { groupSessionsByDate } from './sessions'

export const useSpeakerStore = (speakerId, path = null) => defineStore(`speakers/${speakerId}`, {
    state: () => ({
      document: reactive({}),
      form: markRaw(form),
      fullPath: `${path}/${speakerId}`
    }),
    getters: {
      fields: state => fields,
      speaker: state => state.document,
      speakerName: state => {
        const { speaker } = state
        return `${speaker.name} ${speaker.surname}`
        // return `${speaker.degreeBefore} ${speaker.name} ${speaker.surname} ${speaker.degreeAfter}`
      }, 
      speakerSessions: state => {
        const sessionsStore = useSessionsStore()
        return filter(sessionsStore.documents, session => includes(session.speakers, state.speaker.id))     
      },
      speakerSessionByDate: state => groupSessionsByDate(state.speakerSessions)
    },
    document: true
  }
)()