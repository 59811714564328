<template>
  <el-config-provider :locale="elementLocale">
    <el-container>
      <el-main>
        <el-row 
          :gutter="10"
        >
          <el-col
            :style="{ 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignContent: 'center',
              margin: '0 auto'
            }"
          >
            <img  
              :src="get(fileUrls, 'virtualLogo.png')"
              :style="{
                margin: '0 auto'
              }"
              @click="goHome"
            >
            <div
              :style="{
                margin: '0 auto',
                textAlign: 'center'
              }"
            >
              <h2
                :style="{
                  margin: '0 auto',
                  textAlign: 'center',
                  marginTop: '1em',
                  marginBottom: '1em'
                }"
              >
                <template
                  v-if="loginConferenceId"
                > 
                  {{ $t('login.welcomeToConference', { name: conference.name }) }}
                </template>
                <template v-else>
                  {{ $t('login.welcomeToApp') }}
                </template>
              </h2>

              <Initial
                v-if="!method"
              />

              <Email
                v-if="method === 'email'"
              />

              <Google
                v-if="method === 'google'"
              />
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </el-config-provider>
</template>

<script setup>
import { cloneDeep, get } from 'lodash'
import { computed, watch, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useConferenceStore } from '@/stores/conference'
import { useLoginStore } from '@/stores/login'
import { ElConfigProvider } from 'element-plus'
import dayjs from 'dayjs'
import { getElementLocale } from '@/functions'

import Initial from './login/Initial'
import Email from './login/Email'
import Google from './login/Google'

const conferenceStore = useConferenceStore()
const { conference, fileUrls, loginConferenceId } = storeToRefs(conferenceStore)
const loginStore = useLoginStore()
const { method } = storeToRefs(loginStore)

const elementLocale = computed(() => {
  dayjs.locale(conference.value.locale)
  return getElementLocale(conference.value.locale)
})

</script>