import { defineStore, storeToRefs } from 'pinia'
import { computed, reactive, ref, toRef } from 'vue'
import { filter, mapValues, includes } from 'lodash'
import { getFirestore, setDoc, doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore"
// import { useUserStore } from '@/stores/user'
// import { useConferenceStore } from '@/stores/conference'

export const useLoginStore = (conferenceId = null) => defineStore(`login`, {
    state: () => ({
      conferenceId,
      method: ref(null),
      status: ref(null)
    })
  }
)()