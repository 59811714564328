<template>
  <div>
    <AInput
      :store="contentPageStore"
      field-name="name"
    />

    <LocalizedInput
      :store="contentPageStore"
      field-name="content"
      is-editor
    />
  </div>
</template>

<script setup>
import { defineStore, storeToRefs } from 'pinia'
import { toRefs, computed } from 'vue'
import { values } from 'lodash' 
import { useContentPageStore } from '@/stores/contentPage'
import { useContentPagesStore } from '@/stores/contentPages'
import LocalizedValue from '@/components/LocalizedValue'
import LocalizedInput from '@/components/LocalizedInput'
import AInput from '@/components/AInput'

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

const { id } = props
const contentPageStore = useContentPageStore(id)
const { contentPage } = storeToRefs(contentPageStore)
</script>
